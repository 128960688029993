import React, { Component } from 'react';  
import { Button, Card, CardBody, Col, Form, //Container
        Input, InputGroup,  Row } from 'reactstrap';   

        const _url = (true == true) ? 'https://geared4it.net/Api/login/' : 'http://localhost:54444/Api/login/';
class Register extends Component {
    constructor() {  //publish to live
        super();  
  
    this.state = {  
          Email: '',  
          Password: '', 
          Name: '',
          Surname:'',
          UserName: '',
          EmailError: '',  
          PasswordError: '', 
          NameError: '',
          SurnameError:'',
          UserNameError: '',
          showResults : false,
          showSubmissionDisabled : false,
          showSubmission : true
        }
        
        this.Email = this.Email.bind(this);  
        this.Password = this.Password.bind(this);  
        this.Name = this.Name.bind(this);  
        this.Surname = this.Surname.bind(this);  
        this.UserName = this.UserName.bind(this);      
        this.register = this.register.bind(this);
        this.showResults = this.showResults.bind(this);
    }

    showResults(event) {this.setState({ showResults : event.target.value})};
    Email(event) {   this.setState({ Email: event.target.value }) } ;
    Password(event) {    this.setState({ Password: event.target.value })} ;
    Name(event) {     this.setState({ Name: event.target.value }) } ;       
    Surname(event) {    this.setState({ Surname: event.target.value })} ; 
    UserName(event) {   this.setState({ UserName: event.target.value }) };
      
      //API Call...
      register(event) {
        event.preventDefault();
       
        const pointerToThis = this;
        const isValid = this.validate();

        if(isValid){

          this.setState({
            showSubmissionDisabled: false,
            showSubmission : false
          })

          fetch(_url + 'InsertUser', {
            method: 'post',  
            headers: {  
                'Accept': 'application/json',  
                'Content-Type': 'application/json'  
                },  
             body: JSON.stringify({  
                Name: this.state.Name,  
                Surname: this.state.Surname, 
                Password: this.state.Password,  
                Email: this.state.Email,                  
                UserName: this.state.UserName  
             }) 
        }).then((Response) => Response.json())  
          .then((Result) => {  
            pointerToThis.state.showSubmissionDisabled = true; 
            pointerToThis.state.showSubmission = true; 
                  
                   this.setState({ showResults: true });
                   setTimeout(() => {  this.props.history.push("/login");   }, 6000);
              
                             
        }) 
        }
      }

      validate = () => {

        let NameError = ""
        let SurnameError = ""
        let UsernameError = ""
        let PasswordError = ""
        let EmailError = "";
    
        if(!this.state.Email.includes("@")){
            EmailError = "invalid Email";
        }

        if(EmailError){
            this.setState({ EmailError});
            return false;
        }

        if(!this.state.Name) NameError = "First name cannot be blank";
        if(!this.state.Surname) SurnameError= "Surname cannot be blank";
        if(!this.state.UserName) UsernameError = "User name cannot be blank";
        if(!this.state.Password) PasswordError = "Password cannot be blank";

        if(EmailError || NameError ||  SurnameError || UsernameError || PasswordError){
            this.setState({NameError , SurnameError , UsernameError , PasswordError , EmailError});
            return false;
        }

        return true;
    }
      //html Rendering
      render() {

        if( this.state.showSubmissionDisabled === true){     
          this.state.showSubmission = true;   
        }
        return (  
          <div>
          <br/><br/><br/><br/>
          <div className="h-100 d-flex justify-content-center vertical-center"> 
            <Card className="p-2">
            <CardBody> 
            <Form> 
                <h2> Closed User Group</h2>
                <br/>
                <InputGroup className="mb-3">  
                        <Input type="text"  onChange={this.Name} placeholder="Name" /> 
                        <div style={{fontSize:'10', color: 'red'}}>{this.state.NameError}</div> 
                      </InputGroup>  
                      <InputGroup className="mb-3">  
                        <Input type="text"  onChange={this.Surname} placeholder="Surname" /> 
                        <div style={{fontSize:'10', color: 'red'}}>{this.state.SurnameError}</div> 
                      </InputGroup>  
                      <InputGroup className="mb-3">  
                        <Input type="text"  onChange={this.Email} placeholder="Email" />  
                        <div style={{fontSize:'10', color: 'red'}}>{this.state.EmailError}</div>
                      </InputGroup>                             
                      <InputGroup className="mb-4">  
                        <Input type="text"  onChange={this.UserName} placeholder="Username" />  
                        <div style={{fontSize:'10', color: 'red'}}>{this.state.UsernameError}</div>
                      </InputGroup>  
                      <InputGroup className="mb-4">  
                        <Input type="text"  onChange={this.Password} placeholder="Password" /> 
                        <div style={{fontSize:'10', color: 'red'}}>{this.state.PasswordError}</div> 
                      </InputGroup>  
                      <Button  onClick={this.register}  color="success" block>
                      {this.state.showSubmission ? "Submit Registration" : "Processing..."}
                      </Button>   
                      <p className="message">Already registered? <a href="/login">Log into Account</a></p> 
                      <div>                              
                            { this.state.showResults ? <Results /> : null }
                     </div>
              </Form>
            </CardBody>
             
            </Card>
          </div>
    </div>
          );
      }
 }

 class Results extends Component {
  render() {
      return (
          <strong> <label style={{color:'green'}}>Thank you for your registration. <br/>Our consultants will contact you shortly.</label> </strong> 
      );
  }
};

 export default Register;
  