import React, { Component } from 'react';  
import './App.css';  
import ReactTable   from 'react-table';
import "react-table/react-table.css";

const _url =  (true == true)    ? "https://geared4it.net/Api/Login/"    : "http://localhost:44344/api/Login/";

class GoodTeachingSearchV2 extends Component {
    constructor(props){
        super(props);
    this.state={
        bibleBookVersusDetails:[],
        Data : {
            List : [],
            ResultsFound : ""
        }
    }
        

    };

    componentDidMount(){         
        this.loadGoodTeachingList();
     }

     loadGoodTeachingList=()=>
     { 
       //  e.preventDefault();
      // eslint-disable-next-line no-restricted-globals

      this.setState({
        ResultsFound : ""
      })
        
      fetch(_url + "getGoodTeachings", {
        method: "GET"
      }).then(response => response.json())
      .then(posts => {
        this.setState({
            posts:posts 
        });
      })
     }
 
    loadSelectedBibleVerse(chapter_content){
        //alert(chapter_content)

        this.setState({
            bibleBookVersusDetails : chapter_content
        })
    }
     filterCaseInsensitive = (filter, row) => 
   {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

        return true;
   };

    render()
    {
        const columns = [
            {
                Header: ()=>(
                    <div 
                    style={{
                        textAlign:"left"
                    }}
                    >Id</div>),
                Cell: row => (
                    <div style={{ textAlign:"left"}}>{row.value}</div>
                ),
                    accessor : "author_code",
                    sortable: true,
                    width: 60
            },
            {
                Header : () => (
                   <div style={{
                    textAlign:"left"
                   }}>Full Name</div>),
                Cell: row =>(
                    <div style={{textAlign:"left",backgroundColor:"lightgray"}}>{row.value}</div> 
                ),
                accessor:"full_name",
                sortable: true,
                filterable: true 
            },
            {
                Header : () => (
                   <div style={{
                    textAlign:"left"
                   }}>Title</div>),
                Cell: row =>(
                    <div style={{textAlign:"left",backgroundColor:"lightgray"}}>{row.value}</div> 
                ),
                accessor:"title",
                sortable: true,
                filterable: true 
            },
            {
                Header : () => (
                   <div style={{
                    textAlign:"left"
                   }}>Chapter Title</div>),
                Cell: row =>(
                    <div style={{textAlign:"left",backgroundColor:"lightgray"}}>{row.value}</div> 
                ),
                accessor:"chapter_title",
                sortable: true,
                filterable: true 
            },
            {
                Header: "Actions",
                Cell : props => {
                    return(
                        <button style={{backgroundColor: "green" , color:"#fefefe"}}
                        onClick={()=>{
                            this.loadSelectedBibleVerse(props.original.chapter_content)
                        }}
                        >
                            View
                        </button>
                    )
                } 
            }

        ]
        return(
            <div  className="row"> 
                <div className="col-sm-12 btn btn-primary" >
                <button type="button" style={{marginLeft:"15px"}} className="btn btn-secondary mr-3  float-left" 
                      onClick={() => { document.location.href = "/Login"; }}>Logout</button>
                <button type="button" style={{marginLeft:"15px"}} className="btn btn-warning mr-3 float-left" 
                        onClick={() => { this.props.history.push("/Dashboard"); }}
                        >Dashboard</button>
                <strong>Good Teaching Search </strong>
                </div>
            
                <div className="row col-sm-12">
                    <div className="col-sm-12 body sidepadding">
                        <br/>
                        <ReactTable
                        columns={columns}
                                        data= {this.state.posts}
                                        filterable
                                        defaultPageSize={10} 
                                        noDataText="Bibles List"
                                        defaultFilterMethod={this.filterCaseInsensitive}
                        >

                        </ReactTable>
                    </div>

                </div>

                <div className="row col-sm-12">
                    <div className="body sidepadding">
                        <label><strong>{this.state.bibleBookVersusDetails.reference}</strong> </label>
                        
                            
                        <p style={{fontSize:22}}
                            className="bodyWidth" dangerouslySetInnerHTML={{__html: this.state.bibleBookVersusDetails}}>
                        
                        </p>
                            
                    </div> 
                </div>
            </div>
        )
    }
}

export default GoodTeachingSearchV2; 