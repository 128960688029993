import React, { Component } from 'react';  
import './App.css';  
import { Button,  Form, Input, InputGroup } from 'reactstrap';  
import ReactTable   from 'react-table';
import Select from 'react-select';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

    const _url = (true == true) ? 'https://geared4it.net/Api/login/' : 'http://localhost:54444/Api/login/';
    
    let _param = window.location.pathname.split("/")[2];
    let bodyReference = "";
    //if(_param == undefined || _param === "undefined" || _param === "") window.location.reload();
    const categoryList = [
        { value: 'All', label: 'All' },
        { value: 'Radiation', label: 'Radiation' },
        { value: 'Aqua', label: 'Aqua' },
        { value: 'Biblopedia' , label : 'Biblopedia'}
      ];

      if(_param === undefined || _param === "undefined" || _param === "") _param = "0";
    
    class ClsFigureManager extends React.Component{        
        constructor(props){
            super(props);
              
        this.state = { 
            Id : '',  Title: '',  Meaning: '',   Body: '',
            CategoryName : '', //selected value
            showAdminButton : false,
            CurrentStatus : '',
            showResults : false,
            FileName : '', FilePath : '', 
            FileComment :'', 
            ThumbNail : '',
            Files : [] , 
            FileId : -1,
            showEditImageTitle : false
        }

             this.Title = this.Title.bind(this);  
             this.Meaning = this.Meaning.bind(this);  
             this.Body = this.Body.bind(this);  
             this.CategoryName = this.CategoryName.bind(this);
             this.CurrentStatus = this.CurrentStatus.bind(this);
             this.saveFigureDetails =  this.saveFigureDetails.bind(this); 
             this.saveFigureDetailsLive = this.saveFigureDetailsLive.bind(this);
             this.saveFigureDetailsOnly = this.saveFigureDetailsOnly.bind(this);
             this.handleCategoryChange = this.handleCategoryChange.bind(this);
             this.showResults = this.showResults.bind(this);
             this.FileName = this.FileName.bind(this);  
            this.FilePath = this.FilePath.bind(this);  
            this.FileComment = '';
            this.ThumbNail = '';
            this.Files = this.Files.bind(this);
            this.FileId = this.FileId.bind(this);
            this.RemoveImage = this.RemoveImage.bind(this);
            this.updateImageTitle = this.updateImageTitle.bind(this);
            
        }

        Title(event) {   this.setState({ Title: event.target.value })  } 
        Meaning(event) {  this.setState({ Meaning: event.target.value }) }     
        Body(event) {this.setState({ Body: event.target.value }) }  
        LastUpdated(event) {this.setState({ LastUpdated: event.target.value }) }
        LastUpdatedBy(event) {this.setState({ LastUpdatedBy: event.target.value }) }
        CategoryName(event) { this.setState({ CategoryName: event.target.value})}
        CurrentStatus(event) { this.setState({ CurrentStatus : event.target.value})}
        showResults(event) {this.setState({ showResults : event.target.value})};

        FileName(event) {   this.setState({ FileName: event.target.value })  } 
        FilePath(event) {  this.setState({ FilePath: event.target.value }) }     
    
        Files(event) { this.setState({ Files : event.target.value})}  
        FileId(event) { this.setState({ FileId : event.target.value })}
        

        state = {
            selectedFile : null
        }        
       
        //functions
        loadFigureImages(){
         
             // eslint-disable-next-line no-restricted-globals            
             fetch(_url + 'GetFiles?idx=' + _param , {
                 method : "GET"
             }).then((response) => response.json())//get json object
               .then(response => {            
                   let files = response;
                
                   this.setState({
                       Id : files.Id,                      
                       FileComment : files.FileComment ,
                       Files : files,
                       FileId : files.FileId,
                       ThumbNail : files.ThumbNail
                                 })
               })
        }

        fileSelectedHandler = event => {
            this.setState({
                selectedFile : event.target.files[0]
            })            
        }

        getFileComment = (e) => {
            this.setState({              
              FileComment : e.target.value              
            });
          }

        fileUploadHandler = () => {
            const fd = new FormData();
            const referenceComment = this.state.FileComment;
            
            fd.append('file',this.state.selectedFile,this.state.selectedFile.name); 

                fetch(_url + 'Upload?id=' + _param  +'&comment='+referenceComment + '&userId=' + localStorage.getItem("userId"),{
                    method: 'POST',                    
                    body : fd                    
                })
                .then((Response) => Response.json())
                 .then((result) =>{
                    console.log(result);
                   // this.loadFigureImages();
                   this.saveFigureDetailsOnly();

                   setTimeout(() => {  window.location.reload();  }, 3000);
                   
                   //debugger;
                   
                });
        }

        RemoveImage(id){
           
            //if(idx == null) return;
                fetch(_url + 'DeleteImage',{          
                    method: 'post',
                    headers:{
                      'Accept': 'application/json',  
                      'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify({
                        FileId : id,
                        LinkTitle : window.location.pathname.split("/")[2]// this.state.idx
                    })
                }).then((Response) => Response.json())
                .then((Result) => {
                    if (Result.Status === 'Success') {
                         this.loadFigureImages();  
                    } else  {
                        alert(' Error deleting File!')  
                    }                     
                })
        }

        componentDidMount() {
            this.loadFigureDetails();
            this.loadFigureImages();

            this.setState({            
                showAdminButton: (localStorage.getItem("userIsSuperAdmin") !== "true") ? false : true
            });
        }

        handleCategoryChange (categoryList) {          
            this.setState({CategoryName : categoryList.label });                
          }
        onChange = (e) => this.setState({ [e.target.name]: e.target.value });

        //functions
        loadFigureDetails(){    
            // eslint-disable-next-line no-restricted-globals
            _param = window.location.pathname.split("/")[2];
      

                fetch(_url + 'GetFigureByIdx?idx=' + _param ,{
                    method: "GET"
                }).then((response) => response.json())
                .then(response => {    
                       
                    
                     let figure = response;
                    this.setState({
                        Id : figure.Id,
                        Idx : figure.Idx,
                        Title : figure.Title,
                        Meaning : figure.Meaning,
                        Body : figure.Body,
                        LastUpdated : figure.LastUpdated,
                        LastUpdatedBy : figure.LastUpdatedBy,
                        CategoryName : figure.CategoryName,
                        CurrentStatus : figure.CurrentStatus
                    });
                    bodyReference = figure.Body;//used for display when adding XReferences
                }) 
              }

        saveFigureDetails(event, isDeleted){
            event.preventDefault();
           
            if(isDeleted) this.state.CurrentStatus = "Deleted";
              fetch(_url + 'EditFigure',{          
                    method: "POST",
                    headers:{
                      'Accept': 'application/json',  
                      'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify({
                        Idx : this.state.Idx,
                        Id : this.state.Id,
                        Title: this.state.Title,
                        Meaning : this.state.Meaning,
                        Body : this.state.Body,                        
                        LastUpdatedBy : localStorage.getItem("userId"),
                        CategoryN : this.state.CategoryName ,
                        CurrentStatus : this.state.CurrentStatus     ,
                        UserId :  localStorage.getItem("userId")                 
                    })
                }).then((Response) => Response.json())
                  .then((Result) => {
                      if (Result.Status !== 'Error')  
                      {
                        _param = Result.Status;
                        if(Result.Message !== "Record Deleted."){
                            var newPageUrl = document.location.origin + "/Details/"+ Result.Status;                             
                            this.props.history.push("/Details/"+ Result.Status);
                              window.open(newPageUrl, "_blank") //to open new page
                              window.location.reload();
                        }
                        this.setState({ showResults: true });
                              
                      }                  
                    else 
                    {
                        alert(' Error saving Details!')  
                    } 
                     
                  })
            }

        saveFigureDetailsLive(event){
                event.preventDefault();
                  fetch(_url + 'EditFigureLive',{          
                        method: "POST",
                        headers:{
                          'Accept': 'application/json',  
                          'Content-Type': 'application/json' 
                        },
                        body: JSON.stringify({
                            Idx : this.state.Idx,
                            Id : this.state.Id,
                            Title: this.state.Title,
                            Meaning : this.state.Meaning,
                            Body : this.state.Body,                        
                            LastUpdatedBy : localStorage.getItem("userId"),
                            CategoryN : this.state.CategoryName ,
                            CurrentStatus : 'live'                       
                        })
                    }).then((Response) => Response.json())
                      .then((Result) => {
                          if (Result.Status !== 'Error')  
                          {
                            this.setState({ showResults: true });                                
                                  _param = Result.Status;                               
                                this.props.history.push('/Details/' + _param)
                          }                  
                        else 
                        {
                            alert(' Error saving Details!')  
                        } 
                         
                      })
                }

            saveFigureDetailsOnly(event){
                debugger;
               // event.preventDefault();
                
                 // if(this.state.Idx == null || this.state.Id != 0) return;
                  fetch(_url + 'EditFigure',{          
                        method: "POST",
                        headers:{
                          'Accept': 'application/json',  
                          'Content-Type': 'application/json' 
                        },
                        body: JSON.stringify({
                            Idx : this.state.Idx,
                            Id : this.state.Id,
                            Title: this.state.Title,
                            Meaning : this.state.Meaning,
                            Body : this.state.Body,                        
                            LastUpdatedBy : localStorage.getItem("userId"),
                            CategoryN : this.state.CategoryName   ,
                            CurrentStatus : this.state.CurrentStatus                     
                        })
                    }).then((Response) => Response.json())
                      .then((Result) => {
                         
                          if (Result.Status !== 'Error')  
                          {
                              if(this.state.Idx == null)//new Reference
                              {
                                this.setState({ showResults: true });
                                this.state.Idx = Result.Status;
                              
                                _param = this.state.Idx;                               
                                this.props.history.push('/Details/' + this.state.Idx)
                              }
                          }                  
                        else 
                        {
                            alert(' Error saving Details!')  
                        } 
                         
                      })
                }
                EditTitleModal(props,id){  
                    if(props != null)   this.state.FileComment = props.FileComment;
                    this.state.FileId = id;
                    this.setState({ showEditImageTitle : !this.state.showEditImageTitle})
                  }
                  

                  changeEditImageTitle =(e) =>{                     
                      this.setState({ FileComment : e.target.value})
                }

                updateImageTitle(event){
                     event.preventDefault();
                    
                    // this.state.FileComment = event.FileComment;
                
                    fetch(_url + 'EditAttachmentTitle',{          
                        method: "POST",
                        headers:{
                          'Accept': 'application/json',  
                          'Content-Type': 'application/json' 
                        },
                        body: JSON.stringify({                            
                            FileId : this.state.FileId ,
                            FileComment: this.state.FileComment                                                
                        })
                    }).then((Response) => Response.json())
                      .then((Result) => {
                        window.location.reload();                         
                      })
                }


          render(){

             return(
                 <div>
                     <div className="col-sm-12">
                <div>
                    <strong>Title</strong>
                    <InputGroup className="mb-3"> 
                        <Input type="text" value={this.state.Title || ''} onChange={this.Title.bind(this)} placeholder="Title" />  
                    </InputGroup> 


                    <strong>Meaning</strong>
                    <InputGroup className="mb-3">  
                        <Input type="text"  value={this.state.Meaning || ''} onChange={this.Meaning.bind(this)}  placeholder="Meaning" />  
                    </InputGroup>  
                    
                    <strong>Body</strong>
                    <InputGroup className="mb-3">  
                        <Input type="textarea" value={this.state.Body || ''} onChange={this.Body.bind(this)} placeholder="Body" rows="15"/>  
                    </InputGroup>  

             <strong>Current Category <label style={{color:'green'}}>{this.state.CategoryName || ''}</label> </strong> 
                    <div className="mb-3">                   
                    <Select 
                        defaultValue={categoryList.filter(categoryList => categoryList.label === this.state.CategoryName)}
                        onChange={this.handleCategoryChange}
                        options={categoryList} 
                        placeholder='Select Category'/>
                     </div> 
                     <br/>
                     {

                (_param !== "0") ?
                <div>
                        <div className="row">
                            <div className="col-sm-6">
                            <input type="text" value={this.state.FileComment || ''} 
                                onChange={this.getFileComment} placeholder="File Comment" className="form-control"/>  
                        
                            </div>

                            <div className="col-sm-6">
                            <input type="file" id="file" name="file" onChange={ this.fileSelectedHandler}/> 
                                    <button className="btn btn-success" onClick={this.fileUploadHandler}>Upload</button>
                            </div>
                            <div className="col-sm-6">
                                <strong>Current Status &nbsp;&nbsp;
                                    <label style={{color:'green'}}>{this.state.CurrentStatus || ''}</label> 
                                </strong> 
                            </div>
                                               
                        </div>

                 </div>
                    :
                        <div>
                               Please save New Reference before adding attachments <br/>
                        </div>
  

                     }
                 
                
                     <div>                              
                            { this.state.showResults ? <Results /> : null }
                     </div>
                    
                  
                 
               <br/>
                <Button className="col-sm-2 center success" onClick= { this.saveFigureDetailsOnly } color="success" style={{marginBottom:"3px"}}>Submit for Review</Button>   
                {
                (_param !== "0") ?
                <div>
                <Button className="col-sm-2 center primary" onClick= { (e) => this.saveFigureDetails(e, false) }   color="primary"> Preview </Button>                
                <Button className="col-sm-3 center"  style={{display:(this.state.showAdminButton? 'inline':'none'),marginLeft:"7px"}}  onClick= { this.saveFigureDetailsLive } color="warning">Go Live</Button>  
                <Button className="col-sm-3 center danger" onClick= {(e) => this.saveFigureDetails(e,true) } style={{marginLeft:"7px"}} color="danger"> Delete</Button>
                </div>
                :<div>
                </div>}
               
            </div>
            </div> 
            <div className="col-sm-12">
                 <hr/>
                 <br/>
                <div className="col-sm-12">           
                        {
                        
                        this.state.Files.map((image, imageIndex)=>
                        {                    
                            return <div key={imageIndex}>
                            <span>
                            
                                 <a href={image.ThumbNail}> <img alt="" key={ imageIndex }
                                  src={image.FilePath }                                
                                        style={{width: '15%', height: '15%',padding:'1%'}}/>
                                        <br/>
                                       <strong> {image.FileComment} </strong></a>
                                     <button className="btn btn-danger" onClick={() => this.RemoveImage(image.FileId) }> Delete </button>&nbsp;&nbsp;
                                     <button className="btn btn-success" onClick={() => this.EditTitleModal(image, image.FileId)  }> Edit </button>
                                </span> 
                               <br/><br/>
                            </div> 
                                 
                        })}
                </div>
                             
            </div>   
            
              {/* edit attachment Title*/}
              <Modal isOpen={this.state.showEditImageTitle} className="modal-dialog modal-xl" aria-hidden="true" aria-label="Close">
                  <ModalHeader>
                    <div className="row">
                        <div className="col-sm-12">
                                Attachment Title
                         </div>
                         <div className="col-sm-8">
                                        <input type="text" value={this.state.FileComment || ''}                                                   
                                                placeholder='Image Title'
                                               
                                                onChange={this.changeEditImageTitle}   
                                                className="form-control" />
                         </div>
                         <div className="col-sm-2 pull-left">
                                    <button className="btn btn-success float-left" onClick={this.updateImageTitle}>
                                       Save 
                                    </button> 
                         </div>
                         <div className="col-sm-2 text-right">
                                        <Button onClick={()=>{this.EditTitleModal(null)}}>
                                           Close
                                        </Button>
                                    </div>
                    </div>
                  </ModalHeader>
                  </Modal>
                 </div>
                
            
             ); 
          }
      }
   
 
   class ClsFigureLinksManager extends React.Component{
            constructor(props){
                super(props);

                this.state = {                   
                   linksPosts : [],
                   linkFiguresPosts : [],
                   linkFigureList : [],
                   linkReferenceSelectedOption: null,
                   WikiSearchTerms: '',
                  
                   show : false,
                   ResultsFound : "",
                   showSubmissionDisabled : false,
                   showSubmission : true ,
                   showAddRefResults : false ,
                   addRefMessage : ""
                }
                this.RemoveReference = this.RemoveReference.bind(this);
                this.addFigureReference = this.addFigureReference.bind(this);
                
                
            }
            LinkTitle(event) { this.setState({ LinkTitle : event.target.value})}

            handleChange = (linkReferenceSelectedOption) => {
                this.setState({ linkReferenceSelectedOption });                
              }

            componentDidMount(){                
                this.loadFigureLinks();    
                
                this.state.WikiSearchTerms = "";
                this.loadFigureLinksList();
            }

            loadFigureLinks(){
                 // eslint-disable-next-line no-restricted-globals                
                 fetch(_url + 'GetFigureLinksByIdx?Idx=' + _param,{
                     method : "GET"
                 }).then((response) => response.json())
                   .then(response => {                     
                       this.setState({                        
                        linksPosts : response,
                        WikiSearchTerms: ''
                       })
                   })
            }

            loadFigureLinksList = (e) =>{               
                //e.preventDefault();
                // eslint-disable-next-line no-restricted-globals 
                       
                this.setState({
                    showSubmissionDisabled: false,
                    showSubmission : false,
                    ResultsFound : "",
                    showAddRefResults : false,
                    addRefMessage : ""
                  })
                 

                 fetch(_url + `FigureLinksList?filter=${this.state.WikiSearchTerms}`,{              
                //    fetch(_url + 'XReferences',{   //update           
                    method : "GET"
                }).then((response) => response.json())
                  .then(response => { 
                                    
                      this.setState({     
                        linkFigureList : response,
                        showSubmissionDisabled: false,
                            showSubmission : true,
                            ResultsFound : ""
                        })

                  }) 
            }

            RemoveReference(props){
          
                if(props == null) return;
                fetch(_url + 'DeleteReference',{          
                    method: 'post',
                    headers:{
                      'Accept': 'application/json',  
                      'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify({
                        LinkIdx : props.original.LinkIdx,
                        LinkId : props.original.LinkId,
                        LinkTitle : window.location.pathname.split("/")[2]// this.state.idx
                    })
                }).then((Response) => Response.json())
                .then((Result) => {
                    if (Result.Status === 'Success') {
                         this.loadFigureLinks();  
                    } else  {
                        alert(' Error deleting Reference!')  
                    } 
                    
                })
            }

            addFigureReference(e,props){
             e.preventDefault();
             //resets
             this.setState({ showAddRefResults: false });
             this.setState({ AddRefMessage : ''});

                fetch(_url + 'AddReference',{          
                    method: 'post',
                    headers:{
                      'Accept': 'application/json',  
                      'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify({
                        Link : props.original.value,
                        LinkTitle : props.original.label,// window.location.pathname.split("/")[2]// this.state.idx
                        LinkIdx :  window.location.pathname.split("/")[2],
                        LinkId : props.original.Id
                    })
                }).then((Response) => Response.json())
                .then((Result) => {
                    if (Result.Status === 'Success') {
                        this.setState({ showAddRefResults: true });
                        this.setState({ AddRefMessage : Result.Message});
                         this.loadFigureLinks();  
                    } else  {
                        alert(' Error processing Reference!')  
                    } 
                    
                })

            }

            changeWikiSearchTerms = (e) => {
                this.setState({
                  WikiSearchTerms: e.target.value
                });
              }

            handleModal(props){                              
            this.setState({ show : !this.state.show})
        }

        filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            const content = row[id];
            if (typeof content !== 'undefined') {
                // filter by text in the table or if it's a object, filter by key
                if (typeof content === 'object' && content !== null && content.key) {
                    return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
                } else {
                    return String(content).toLowerCase().includes(filter.value.toLowerCase());
                }
            }
        
            return true;
        };

            render(){

                const columns =[{
                    Header : () => (
                        <div
                          style={{
                            textAlign:"left"
                          }}
                        ><strong>Reference</strong></div>),
                        accessor : "LinkIdxFriendlyName",
                        sortable: true,
                        filterable : true,

                },

                {
                    Header : () => (
                            <div style={{ textAlign : "left"}}>
                               
                                <strong>Actions</strong>  </div>
                    ),
                    Cell: (props)=> (
                        <div>
                            <button style={{backgroundColor: "red" , color:"#fefefe"}}
                         onClick={() =>{ this.RemoveReference(props)
                         }}> Remove  </button>
                         &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                            <button style={{backgroundColor: "green"}} >
                              <a  href={"https://cugonline.co.za/Details/" + props.original.LinkIdx}   style={{color:"white"}}> View</a>
                            </button>
                            
                        </div>
                        
                         
                    )}
            ]
            const xrefcolumns = [ {
                Header : () => (
                        <div
                          style={{
                            textAlign:"left"
                          }}
                        ><strong>Title</strong></div>),
                        accessor : "label",
                        sortable: true,
                        filterable : true,
                        width : 400

                },
                {
                    Header : () => (
                            <div
                              style={{
                                textAlign:"left"
                              }}
                            ><strong>Meaning</strong></div>),
                            accessor : "meaning",
                            sortable: true,
                            filterable : true,
                            width : 500
    
                    },
      

            {
                Header :  "Actions",
                Cell : props =>{
                    return(
                      <div>
                        <button style={{marginRight:"7px" ,backgroundColor: "orange" , color:"#fefefe" }} onClick= {(e) => this.addFigureReference(e,props) } >                              
                          <a  
                          
                          style={{color:"white"}}> Add </a>
                          </button> 
                        
                        <button style={{ backgroundColor: "green" , color:"#fefefe"}}>
                          <a target="_blank"  href={"https://cugonline.co.za/Details/" + props.original.value}   style={{color:"white"}}>View</a>
                        </button>    
                      </div>
                        
                    )
                },
               
               
                sortable: false,
                filterable : false,
                width : 120,
                maxwidth : 120,
                minwidth : 120
            }
        ]

                return(
                    <>
                    <div className="col-sm-12">
                    <div className="col-sm-12">                
                 
                    {
                        (_param !== "0") ?
                  
                        <div>
                              <div className="col-sm-12 float-left">                                   
                                    <Button className="float-right btnpadding" onClick={()=>{this.handleModal(null)}}  color="success">Search XReferences</Button>                                    
                                    <br/><br/>
                                </div>

                                <div>
                                <ReactTable 
                                    columns={columns}
                                    noDataText="no records..."
                                        data= {this.state.linksPosts}
                                        filterable
                                        defaultPageSize={15} 
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        >
                                                
                                    </ReactTable>
                                </div>
                        </div>
                         :
                        <div>
                               Please save New Figure before adding Cross Reference
                        </div>
                    } 

                </div> 
          
                </div>
                
                 {/* edit modal  */}     
                 <Modal isOpen={this.state.show} className="modal-dialog modal-xl" aria-hidden="true">
                         <ModalHeader>
                             <div className="row">
                                <div className="col-sm-12">
                                    Select XReferences 
                                </div>

                                <div className="col-sm-6">
                                        <input type="text" value={this.state.WikiSearchTerms || ''}
                                                onChange={this.changeWikiSearchTerms}   
                                                placeholder='Search References...'
                                                className="form-control" />
                                </div>
                                <div className="col-sm-2 pull-left">
                                    <button className="btn btn-primary float-left" onClick={this.loadFigureLinksList}>
                                        {this.state.showSubmission ? "Search" : "Processing..."}
                                    </button> 
                                </div>
                                
                                { this.state.showAddRefResults ? 
                                 <div className="col-sm-4 pull-left">                              
                                     <label className="btn btn-success">{this.state.AddRefMessage} </label>
                                  </div>
                                : null }
                               
                                
                                <div className="col-sm-3 text-right hidden" hidden>
                                    <strong className="btn btn-success"> {this.state.ResultsFound }</strong>
                                </div>                               
                             </div>
                            
                         </ModalHeader>
                         <ModalBody>
                         <div className="row">
                            <div className="col-sm-12"  style={{paddingBottom: '15px'}}>
                                <ReactTable 
                                    columns={xrefcolumns}                                
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        data= {this.state.linkFigureList}
                                        filterable
                                        defaultPageSize={7} 
                                        noDataText="Loading References">                                
                                </ReactTable>                                    
                            </div>   
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-6">
                                         <strong>Body Reference</strong> 
                                    </div>
                                    <div className="col-sm-6 text-right">
                                        <Button onClick={()=>{this.handleModal(null)}}>
                                           Close Window
                                        </Button>
                                    </div>
                                </div>
                                
                                <p className="bodyWidth" dangerouslySetInnerHTML={{__html: bodyReference}}>
                                </p>  
                            </div>                         
                         </div>   
                         </ModalBody>
                         <ModalFooter>
                             <Button onClick={()=>{this.handleModal(null)}}>
                             Close 
                             </Button>
                             
                         </ModalFooter>
                     </Modal>
                </>
                )
            }

      }
 
  class FigureDetails extends Component {  

    constructor(props){
        super(props);  
    }

    render() {  

        return (  
            <div  className="row mb-2 pageheading">  
                <div className="col-sm-12 btn btn-primary" style={{marginLeft:"15px",marginBottom:"15px"}}>  
                    <button type="button" className="btn btn-warning mr-3 float-left" onClick={() => { this.props.history.push("/Dashboard"); }}>Dashboard</button>                    
                    &nbsp;&nbsp; <button type="button" className="btn btn-success mr-3 float-left" onClick={() => { this.props.history.push("/Figures"); }}>Back to References</button>                    
                    <strong> Figure Management </strong>
                 </div>  

               
                    {/*Figures Manager  */}
                    <div className="col-sm-8">                 

                    {                           
                      <ClsFigureManager {...this.props}/>                            
                    }
                    </div>

                <div className="col-sm-4">
                    <br/> 
                    {                      
                         <ClsFigureLinksManager {...this.props}/>                         
                    }               
                   
                    </div> 
                </div> 
                );  
    }
      
}  
  
class Results extends Component {
    render() {
        return (
            <strong> <label style={{color:'green'}}>Reference Updated Successfully!</label> </strong> 
        );
    }
};
export default FigureDetails;  