import React, { Component } from 'react';  
 
import { Button, Card, CardBody,  Form, Input, InputGroup } from 'reactstrap';  
const _url = (true == true) ? 'https://geared4it.net/Api/login/' : 'http://localhost:54444/Api/login/';

class Login extends Component { 
    constructor() {  
        super();  
  
        this.state = {  
            UserName: '',  
            Password: ''
        }   
  
        this.Password = this.Password.bind(this);  
        this.UserName = this.UserName.bind(this);  
        this.login = this.login.bind(this); 
        
        localStorage.setItem("userName", "")
        localStorage.setItem("userCategoryRole", "")
        localStorage.setItem("userIsAdmin", "")//isEdit
        localStorage.setItem("userId", "")//is user
        localStorage.setItem("userIsSuperAdmin","")//is esuper admin            
        localStorage.setItem("expiryDate",""); 
    }  
  
    UserName(event) {  
        this.setState({ UserName: event.target.value })  
    }  
    Password(event) {  
        this.setState({ Password: event.target.value })  
    }  
    login(event) {  
          
        fetch(_url + 'Login', {  
            method: 'post',  
            headers: {  
                'Accept': 'application/json',  
                'Content-Type': 'application/json' ,
                'Access-Control-Allow-Origin': '*"' ,
                'withCredentials': true,    
                'crossorigin': true,    
                'mode': 'no-cors',  
            },  
            body: JSON.stringify({  
                UserName: this.state.UserName,  
                Password: this.state.Password  
            })  
        }).then((Response) => Response.json())  
            .then((result) => {  
                  

                if (result.Name === 'Invalid')  
                    alert('Invalid User');  
                else  
                
                    if(result.IsEditor == true){
                        //do roles...
                        localStorage.setItem("userName", result.Name + " " + result.Surname)
                        localStorage.setItem("userCategoryRole", result.CategoryName)
                        localStorage.setItem("userIsAdmin", result.IsEditor)//isEdit
                        localStorage.setItem("userId", result.Id)//is user
                        localStorage.setItem("userIsSuperAdmin", result.IsSuperAdmin)//is esuper admin
                        
                        let expiryDate = new Date();
                        var day = expiryDate.getDate();
                        var month = expiryDate.getMonth();
                        var year = expiryDate.getFullYear();
                        var expiryDateString = day + '-' + month + '-' + year;
                    
                        localStorage.setItem("expiryDate",expiryDateString);

                        if(localStorage.getItem("userIsSuperAdmin") == "true"){
                            this.props.history.push("/Dashboard"); 
                        }else{
                            this.props.history.push("/Figures"); 
                        }
                      
                    }else{
                       // this.props.history.push("/search"); 
                    }                     
            })  
    }  
  
    render() {  
  
        return (  
            <div>
                <br/><br/><br/><br/>
            <div className="h-100 d-flex justify-content-center vertical-center"> 
                           
                                <Card className="p-2">  
                                    <CardBody>  
                                        <Form>  
                                               <h2> Closed User Group</h2>
                                                    <br/>                                                
                                            <InputGroup className="mb-3">  
  
                                                <Input type="text" onChange={this.UserName} placeholder="User Name" />  
                                            </InputGroup>  
                                            <InputGroup className="mb-4">  
  
                                                <Input type="password" onChange={this.Password} placeholder="Password" />  
                                            </InputGroup>  
                                            <Button onClick={this.login} color="success" block>Login</Button>  
                                            <p className="message">Not registered? <a href="/Register">Register an Account</a></p>
                                        </Form>  
                                    </CardBody>  
                                </Card>  
            </div>  

            </div>
            
            
        );  
    }  
}  
  
export default Login;  