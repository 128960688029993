import React from "react";

function Footer() {
  return (
    <div className="center footer">
      {/* <h6>Powered by Geared4IT</h6> */}
    </div>
  );
}

export default Footer;