import React, { Component } from 'react';  
import './App.css';  
import ReactTable   from 'react-table';
import "react-table/react-table.css";

const _url = (true == true) ? 'https://geared4it.net/Api/login/' : 'http://localhost:54444/Api/login/';

  class FiguresPagintion extends Component {  
      constructor(props){
          super(props);

          this.state = {
              posts : [],
              wikiSearchTerms: '',
              showButton : false,
              Data : {
                List : [],
                totalPage : 0,
                sortColumnName : "Title",
                sortOrder : "asc",
                currentPage : 1,
                pageSize : 15
            }
          }
      }

      componentDidMount(){       
        this.setState({
            showButton: (localStorage.getItem("userIsSuperAdmin") !== "true") ? false : true
        });
        this.state.WikiSearchTerms = "";
        this.loadFigures();
       // this.loadFiguresPagination();
      }

      loadFiguresPagination = () => {
      // eslint-disable-next-line no-restricted-globals
      var params = {
          pageSize : this.state.Data.pageSize,
          currentPage : this.state.Data.currentPage
         }
        if(this.state.Data.sortColumnName){
            params.sortColumnName = this.state.Data.sortColumnName;
        }
        if(this.state.Data.sortOrder){
            params.sortOrder = this.state.Data.sortOrder;
        }

      fetch(_url + 'FiguresPagination?pageSize='+ params.pageSize +'&currentPage=' + params.currentPage,{
      //fetch(_url + 'FiguresPagination',{
          method: "GET",
          data : params,
       }).then(response => response.json())
        .then(data=>{
          
          //this.setState({posts:data})
          this.setState({posts:data.Data.List})
        })
      }
      
      pageChanged = (pageNumber,e)=>{
      //e.preventDefault();
      this.state.Data.currentPage = pageNumber;
      //this.populateData();
      //this.loadFiguresPagination();
      
        this.setState({pageSize: pageNumber}); 
      }


      loadFigures = () => {         
      //  e.preventDefault();
      // eslint-disable-next-line no-restricted-globals

        fetch(_url + `Figures?search=${this.state.WikiSearchTerms}`,{
        //  fetch(_url + 'Figures',{
            method: "GET"
        }).then(response => response.json())
          .then(posts => {     
                    
              this.setState({posts:posts})
          })
      }

      changeWikiSearchTerms = (e) => {
        this.setState({
          WikiSearchTerms: e.target.value
        });
      }

      filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }
    
        return true;
    };

    render() {  
            const columns = [ {
                    Header :  () => (
                        <div
                          style={{
                            textAlign:"left"
                          }}
                        >Title</div>),
                        Cell: row => (
                            <div style={{ textAlign: "left" }}>{row.value}</div>
                          ),
                    accessor : "Title",
                    sortable: true,
                    filterable : true,
                    width : 560,
                },

             
                
                {
                    Header :  "Actions",
                    Cell : props =>{
                        return(
                          <div>
                            <button style={{marginRight:"7px" ,backgroundColor: "orange" , color:"#fefefe" , display:(this.state.showButton? 'inline':'none')}}>                              
                              <a  href={"https://cugonline.co.za/FigureDetails/" + props.original.Idx}   style={{color:"white"}}> Edit</a>
                              </button> 
                            
                            <button style={{ backgroundColor: "green" , color:"#fefefe"}}>
                              <a  href={"https://cugonline.co.za/Details/" + props.original.Idx}   style={{color:"white"}}>View</a>
                            </button>    
                          </div>
                            
                            
                            
                        )
                    },
                  sortable: false,
                  filterable : false,
                  width : 105
                }
            ]
        return (  
            <div  className="row">  

            {
              
            }
                <div className="col-sm-12 btn btn-primary" > 
                <button type="button" style={{marginLeft:"15px"}} className="btn btn-secondary mr-3  float-left" 
                    onClick={() => { document.location.href = "/Login"; }}>Logout</button>

                <button type="button" style={{marginLeft:"15px"}} className="btn btn-warning mr-3 float-left" 
                  onClick={() => { this.props.history.push("/Dashboard"); }}
                  style={{display:(this.state.showButton? 'inline':'none')}}>Dashboard</button> 
                  
            <strong>Manage References</strong>
                      <button className="btn btn-success mr-3 float-right"  onClick= {() => { this.props.history.push('/FigureDetails/0') }} 
                      style={{display:(this.state.showButton? 'inline':'none')}}
                          color="success"  block="true">Add Reference</button>  
                </div> 
               

                 <div className="row col-sm-12" style={{marginTop:"15px"}}>
                      <div className="col-sm-4 sidepadding body input-group">                
                                  <input type="text" value={this.state.WikiSearchTerms || ''} 
                                          onChange={this.changeWikiSearchTerms} className="form-control" placeholder="Search References..." />
                                  
                                  <span className="input-group-btn"> &nbsp;&nbsp;
                                  <button className="btn btn-info"  ref="btn" type='submit'  onClick={this.loadFigures}
                                              color="success" block="true">Search</button>  
                                  </span>
                        </div>  
                </div>
           
                   <div className="row col-sm-10">
                    <div className="col-sm-8 body sidepadding">
                    <br/>
                    <ReactTable 
                    columns={columns}
                    defaultFilterMethod={this.filterCaseInsensitive}
                    data= {this.state.posts}
                    filterable
                    defaultPageSize={15} 
                    noDataText="Loading References"
                    onPageChange={this.pageChanged} 
                    currentPage = {this.state.Data.currentPage}
                    totalPage = {this.state.Data.pageSize}
                    pageSize = {this.state.Data.pageSize}
                    activePage={this.state.activePage} 

                    itemsCountPerPage={10} 
                    totalItemsCount={450} 
                    pageRangeDisplayed={5} 
                    onChange={this.handlePageChange} 
                    
                    >
                                
                    </ReactTable>
                    </div>
                </div> 
             
            </div>  

          
        );  
    }  
}  
  
export default FiguresPagintion;  