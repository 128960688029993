import React, { Component } from 'react';  
import './App.css';  
import ReactTable   from 'react-table';
import "react-table/react-table.css";

const _url = (true == true) ? 'https://geared4it.net/Api/login/' : 'http://localhost:54444/Api/login/'; 

  class Figures extends Component {  
      constructor(props){
          super(props);

          this.state = {
              posts : [],
              wikiSearchTerms: localStorage.getItem("lastSearchItem"),
              showButton : false,
              isSearchAttachments : false,// localStorage.getItem("isAttachmentsChecked"),
              isStartsWith : false,// localStorage.getItem("isStartsWithChecked"),
              Data : {
                List : [],
                totalPage : 0,
                sortColumnName : null,
                sortOrder : null,
                currentPage : 1,
                pageSize : 3,
                showSubmissionDisabled : false,
                showSubmission : true,
                ResultsFound : ""
                
            }
          }

          this.isSearchAttachments = this.isSearchAttachments.bind(this);
          this.isStartsWith = this.isStartsWith.bind(this);
      }

      isSearchAttachments(event) 
      {
          const target = event.target;
          const value = target.type === 'checkbox' ? target.checked : target.value;
          const name = target.name;
          this.setState({ [name] : value})
      }

      isStartsWith(event) 
      {
          const target = event.target;
          const value = target.type === 'checkbox' ? target.checked : target.value;
          const name = target.name;
          this.setState({ [name] : value})
      }

      componentDidMount(){       
        this.setState({
            showButton: (localStorage.getItem("userIsSuperAdmin") !== "true") ? false : true
        });

        this.state.WikiSearchTerms = localStorage.getItem("lastSearchItem");

        this.setState({
          showSubmissionDisabled: false,
          showSubmission : false,
          ResultsFound : "",
          isSearchAttachments : false,
          isStartsWith : false
        })
 
        this.loadFigures();        
      }
     
      loadFigures = () => {         
      //  e.preventDefault();
      // eslint-disable-next-line no-restricted-globals

      this.setState({
        showSubmissionDisabled: false,
        showSubmission : false,
        ResultsFound : "" 
      })
     
     
        fetch(_url + "Figures?search=" + this.state.WikiSearchTerms + "&isAdmin="+ localStorage.getItem("userIsSuperAdmin")+ "&isSearchAttachments="  + this.state.isSearchAttachments + "&isStartsWith="  + this.state.isStartsWith,{
                    method: "GET"
        }).then(response => response.json())
          .then(posts => { 

            localStorage.setItem("lastSearchItem",this.state.WikiSearchTerms); 
            // localStorage.setItem("isAttachmentsChecked",this.state.isSearchAttachments); 
            // localStorage.setItem("isStartsWithChecked",this.state.isStartsWith);
            
            //this.state.showSubmissionDisabled = true;    
            this.setState({showSubmissionDisabled:true}) ;
            //this.state.showSubmission = true;   
            this.setState({showSubmission:true});
           // this.state.ResultsFound = "Search Records found: " + posts.length
            this.setState({ResultsFound:"Search Records found: " + posts.length});
              this.setState({posts:posts});
          })
      }

      changeWikiSearchTerms = (e) => {
        this.setState({
          WikiSearchTerms: e.target.value
        });
      }

      filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }
    
        return true;
    };

    render() {  

      if( this.state.showSubmissionDisabled === true){     
        this.state.showSubmission = true;   
      }
            const columns = [ 
              {
              
                Header :  () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Id</div>),
                    Cell: row => (
                        <div style={{ textAlign: "left" }}>{row.value}</div>
                      ),
                accessor : "Id",
                sortable: true,
                filterable : true,
                width : 40
            },
              {
              
                    Header :  () => (
                        <div
                          style={{
                            textAlign:"left"
                          }}
                        >Title</div>),
                        Cell: row => (
                            <div style={{ textAlign: "left" }}>{row.value}</div>
                          ),
                    accessor : "Title",
                    sortable: true,
                    filterable : true,
                    width : 300
                },
                {
                  Header :  () => (
                      <div
                        style={{
                          textAlign:"left"
                        }}
                      >Body</div>),
                      Cell: row => (
                          <div style={{ textAlign: "left",backgroundColor:"lightgray" }}>{row.value}</div>
                        ),
                  accessor : "Body",
                  sortable: true,
                  filterable : true
              },
              {
                Header :  () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    > </div>),
                    Cell: row => (
                        <div style={{ textAlign: "left",backgroundColor:"lightgray" }}> </div>
                      ),
                accessor : null,
                sortable: true,
                filterable : true,
                width : 40
            },
              {
                Header :  () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Meaning</div>),
                    Cell: row => (
                        <div style={{ textAlign: "left" ,backgroundColor:"smokewhite" }}>{row.value}</div>
                      ),
                accessor : "Meaning",
                sortable: true,
                filterable : true
            },
            {
              Header :  () => (
                  <div
                    style={{
                      textAlign:"left"
                    }}
                  >Status</div>),
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>{row.value}</div>
                    ),
              accessor : "CurrentStatus",
              sortable: true,
              filterable : true,                  
              width : 80
          },
            {
              Header :  () => (
                  <div
                    style={{
                      textAlign:"left"
                    }}
                  >Attachments</div>),
                  Cell: row => (
                      <div style={{ textAlign: "center" }}>
                        <button className="btn btn-success" value={row.value}
                                    style={{marginRight:"7px" ,backgroundColor: "green" , color:"#fefefe" , display:(row.value === "Yes" ? 'inline':'none')}}>
                                      YES</button>         
                      </div>
                    ),
              accessor : "HasAttachments",
              sortable: true,
              filterable : true,                  
              width : 100
          },       
                {
                    Header :  "Actions",
                    Cell : props =>{
                        return(
                          <div>
                       
                              <button className="btn btn-success" style={{marginRight:"7px" ,backgroundColor: "orange" , color:"#fefefe" , display:(this.state.showButton? 'inline':'none')}}
                               onClick={()=> window.location.href='/FigureDetails/' + props.original.Idx}>Edit</button>
                           
                             <button className="btn btn-success"  style={{ backgroundColor: "green" , color:"#fefefe"}}
                             onClick={()=> window.location.href='/Details/' + props.original.Idx}>View</button>  
                          </div>
                            
                        )
                    },
                  sortable: false,
                  filterable : false,                  
                  width : 200
                }
            ]
        return (  
            <div  className="row">  

              <div className="col-sm-12 btn btn-primary" > 
                  <button type="button" style={{marginLeft:"15px"}} className="btn btn-secondary mr-3  float-left" 
                      onClick={() => { document.location.href = "/Login"; }}>Logout</button>

                      <button type="button" style={{marginLeft:"15px",display:(this.state.showButton? 'inline':'none')}} className="btn btn-warning mr-3 float-left" 
                        onClick={() => { this.props.history.push("/Dashboard"); }}
                        >Dashboard</button> 
                  
                      <strong>Manage References</strong>
                      <button className="btn btn-success mr-3 float-right"  onClick= {() => { this.props.history.push('/FigureDetails/0') }} 
                      style={{display:(this.state.showButton? 'inline':'none')}}
                          color="success"  block="true">Add Reference</button>  
              </div> 
               

                 <div className="row col-sm-12" style={{marginTop:"15px"}}>
                      <div className="col-sm-4 sidepadding body input-group">                
                                 <span> <input type="text" value={this.state.WikiSearchTerms || ''} 
                                          onChange={this.changeWikiSearchTerms} className="form-control" placeholder="Search References..." />
                                </span>
                                  <span className="input-group-btn"> &nbsp;&nbsp;
                                  <button className="btn btn-info"  ref="btn" type='submit'  onClick={this.loadFigures}
                                              color="success" block="true"> {this.state.showSubmission ? "Search" : "Processing..."}</button>  
                                  </span>
                        </div>  
                        <div className="col-sm-8">
                            <strong className="btn btn-success"> {this.state.ResultsFound }</strong>
                        </div>
                </div>
            <div className="row col-sm-12">
            <div className="col-sm-2 sidepadding body input-group">    
            <label>
                <input
                        checked={this.state.isSearchAttachments} 
                        onChange={this.isSearchAttachments.bind(this)} 
                        name="isSearchAttachments"
                        type="checkbox"
                        />
                <strong> Incl. Attachments &nbsp;  </strong> 
              </label>
            </div>
            <div className="col-sm-9">   
            <label>
                <input
                        checked={this.state.isStartsWith} 
                        onChange={this.isStartsWith.bind(this)} 
                        name="isStartsWith"
                        type="checkbox"
                        />
                <strong>  Starts With &nbsp;  </strong> 
              </label>
            </div>
           </div>
           
           <div className="row col-sm-12">
                    <div className="col-sm-12 body sidepadding">
                    <br/>
                    <ReactTable 
                    columns={columns}
                    defaultFilterMethod={this.filterCaseInsensitive}
                    data= {this.state.posts}
                    filterable
                    defaultPageSize={20} 
                    noDataText="Loading References">
                                
                    </ReactTable>
                    </div>
            </div> 
             
            </div>  

          
        );  
    }  
}  
  
export default Figures;  