import React, { Component } from 'react';  
import './App.css';  
import {  } from 'reactstrap';  
import ReactTable   from 'react-table';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const _url = (true == true) ? 'https://geared4it.net/Api/login/' : 'http://localhost:54444/Api/login/';


  class Dashboard extends Component {  
    constructor(props){
        super(props);

        let expiryDate = new Date();

        var day = expiryDate.getDate();
        var month = expiryDate.getMonth();
        var year = expiryDate.getFullYear();
        var expiryDateString = day + '-' + month + '-' + year;

        if (expiryDateString !== localStorage.getItem("expiryDate") || localStorage.getItem("userId") === "") {
            
            localStorage.setItem("userName", "")
            localStorage.setItem("userCategoryRole", "")
            localStorage.setItem("userIsAdmin", "")//isEdit
            localStorage.setItem("userId", "")//is user
            localStorage.setItem("userIsSuperAdmin","")//is esuper admin            
            localStorage.setItem("expiryDate","");            
          
            this.props.history.push("/Login"); 
           // return;
        }

        this.state = {
            name : '',
            showUsersButton : false,
            figureStatistics : [],
            figureCurrentStatus : [],
            figureEmptyReference : [],
            figureReportDetails : [],
            showDetailTableVisible : false,
            showEmptyReferenceDetailTableVisible : false,
            userLoginHistoryDetails : [],
            userActivityEditHistoryDetails : [],
            loginFrom: new Date(Date.now() - (1200 * 1000 * 168)),//last 14 days
            loginTo: new Date(Date.now()),
            showUserActivitiesReport : (localStorage.getItem("userIsSuperAdmin") == "true"),
            showNewRegistration : false
            
        }

        this.handleLoginFromChange = this.handleLoginFromChange.bind(this);
        this.handleLoginToChange = this.handleLoginToChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    handleLoginFromChange(date) {
        this.setState({
            loginFrom: date
        })
      }

      handleLoginToChange(date) {
        this.setState({
            loginTo: date
        })
      }

      onFormSubmit(e) {
        e.preventDefault();
        this.getUserActivityLoginHistory(this.state.loginFrom.toDateString() , this.state.loginTo.toDateString());
        this.getUserActivityEditHistory(this.state.loginFrom.toDateString() , this.state.loginTo.toDateString());        
      }
   

    componentDidMount()
    {       

        if(localStorage.getItem("userId") !== "") {
            this.props.history.push("/Dashboard"); 
        }else{
            this.props.history.push("/login"); 
        }      
        
        this.setState({            
            showUsersButton: (localStorage.getItem("userIsSuperAdmin") !== "true") ? false : true
        });

        this.loadFigureStatistics();
        this.loadFigureCurrentStatus();
        this.loadFigureEmptyReference();
        this.getUserActivityLoginHistory(this.state.loginFrom.toDateString() , this.state.loginTo.toDateString());
        this.getUserActivityEditHistory(this.state.loginFrom.toDateString() , this.state.loginTo.toDateString());
        this.getNewRegistration();
    }

    loadFigureStatistics(){
        // eslint-disable-next-line no-restricted-globals
        fetch(_url + 'GetFigureStatistics',{
                method : "GET"
        }).then(response => response.json())
          .then(response => {
              this.setState({
                  figureStatistics : response
              })
          });
    }

    loadFigureCurrentStatus(){
          // eslint-disable-next-line no-restricted-globals
          fetch(_url + 'GetFigureCurrentStatus',{
            method : "GET"
    }).then(response => response.json())
      .then(response => {
          this.setState({
              figureCurrentStatus : response
          })
      });
    }

    loadFigureEmptyReference(){
        // eslint-disable-next-line no-restricted-globals
        fetch(_url + 'GetDatabaseStatistics',{
            method : "GET"
    }).then(response => response.json())
      .then(response => {
          this.setState({
              figureEmptyReference : response
          })
      });
    }

    getUserActivityLoginHistory(loginFrom, loginTo){
        // eslint-disable-next-line no-restricted-globals
        fetch(_url + 'GetUserActivityLoginHistory?loginFrom='+ loginFrom +'&loginTo=' + loginTo,{
            method : "GET"
        }).then(
            (response) => response.json()
        ).then(response => {
               this.setState({
                   userLoginHistoryDetails : response
               })
        });
    }

    getUserActivityEditHistory(loginFrom, loginTo){
        // eslint-disable-next-line no-restricted-globals
        fetch(_url + 'GetUserActivityEditHistory?loginFrom='+ loginFrom +'&loginTo=' + loginTo,{
            method : "GET"
        }).then(
            (response) => response.json()
        ).then(response => {
               this.setState({
                   userActivityEditHistoryDetails : response
               })
        });
    }

    getNewRegistration(){
         // eslint-disable-next-line no-restricted-globals
         fetch(_url + 'GetNewRegistration',{
            method : "GET"
        }).then(
            (response) => response.json()
        ).then(response => {
               this.setState({
                   showNewRegistration : response
               })
        });
    }


    loadReportDetails(filter , field){
         // eslint-disable-next-line no-restricted-globals
         fetch(_url + 'GetReportDetails?filter='+ filter +'&field=' + field,{
             method : "GET"
         }).then(
             (response) => response.json()
         ).then(response => {
                this.setState({
                    figureReportDetails : response
                })

                this.toggleOnClick(false);
         });
    }

    loadEmptyReferenceReportDetails(type){
        // eslint-disable-next-line no-restricted-globals
        var filter = 50;
        fetch(_url + 'GetEmptyReferenceReportDetails?filter='+ filter +'&type=' + type,{
            method : "GET"
        }).then(
            (response) => response.json()
        ).then(response => {
               this.setState({
                   emptyReferenceReportDetails : response
               })

               this.toggleEmptyReferenceClick(false);
        });
   }


    toggleOnClick(isHidden) {
        this.setState({showDetailTableVisible: !isHidden});
        this.setState({showEmptyReferenceDetailTableVisible : false});
      }

      toggleEmptyReferenceClick(isEmptyHidden){
          this.setState({showEmptyReferenceDetailTableVisible : !isEmptyHidden});
          this.setState({showDetailTableVisible: false});
      }

      /**/

      filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }
    
        return true;
    };

  render() {  
  
    const columns = [
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}><strong> Category Details</strong> </div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.Category}
                    </div>
            ),
            accessor : "Category"
        },
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}><strong>Total</strong></div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.TotalCount}
                    </div>
            ),
            accessor : "TotalCount"
        },
        
        {
            Header : "Actions",
            Cell : props => {
                return(
                    <button style={{backgroundColor : "green" , color:"#fefefe"}}
                    onClick = {() => {
                    
                          this.loadReportDetails(props.original.Category, "Category") //1 = Category                          
                    }}
                    > View</button>
                )
            }
        }
    ]

    const colCurrentStatus = [
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}><strong>Reference Status</strong> </div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.CurrentStatus}
                    </div>
            ),
            accessor : "Category"
        },
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}><strong>Total</strong></div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.TotalCount}
                    </div>
            ),
            accessor : "TotalCount"
        },
        
        {
            Header : "Actions",
            Cell : props => {
                return(
                    <button style={{backgroundColor : "green" , color:"#fefefe"}}
                    onClick = {() => {
                    
                           this.loadReportDetails(props.original.CurrentStatus,'CurrentStatus') //0 = Current Status                          
                    }}
                    > View</button>
                )
            }
        }
    ]

    const colEmptyReference = [
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}><strong>Empty Details </strong> </div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.Title}
                    </div>
            ),
            accessor : "Title"
        },
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}><strong>Total</strong></div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.TotalRecords}
                    </div>
            ),
            accessor : "TotalRecords"
        },
        
        {
            Header : "Actions",
            Cell : props => {
                return(
                    <button style={{backgroundColor : "green" , color:"#fefefe"}}
                    onClick = {() => {                    
                           this.loadEmptyReferenceReportDetails(props.original.Title) //0 = Current Status                          
                    }}
                    > View</button>
                )
            }
        }
    ]

    const colEmptyReferenceReportDetails = [{
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Title</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "Title",
        sortable: true,
        filterable : true
    },
    {
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Category</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "CategoryName",
        sortable: true,
        filterable : true
    },

    {
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Status</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "CurrentStatus",
        sortable: true,
        filterable : true
    },

    {
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Last Updated</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "LastUpdated",
        sortable: true,
        filterable : true
    },

    {
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Updated By</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "LastUpdatedBy",
        sortable: true,
        filterable : true
    },
    
    {
        Header :  "Actions",
        Cell : props =>{
            return(
                <button style={{backgroundColor: "green" , color:"#fefefe"}}
                 onClick = {() => {
                 
                    this.props.history.push('/FigureDetails/' + props.original.Idx)
                }}
                >Edit</button>                           
            )
        },
       
        sortable: false,
        filterable : false,
        width : 100,
        maxwidth : 100,
        minwidth : 100
    }]

    const colFigureReportDetails = [{
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Title</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "Title",
        sortable: true,
        filterable : true
    },
    {
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Category</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "CategoryName",
        sortable: true,
        filterable : true
    },

    {
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Status</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "CurrentStatus",
        sortable: true,
        filterable : true
    },

    {
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Last Updated</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "LastUpdated",
        sortable: true,
        filterable : true
    },

    {
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Updated By</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "LastUpdatedBy",        
        filterable : true
    },
    
    {
        Header :  "Actions",
        Cell : props =>{
            return(
                <button style={{backgroundColor: "green" , color:"#fefefe"}}
                 onClick = {() => {
                 
                    this.props.history.push('/FigureDetails/' + props.original.Idx)
                }}
                >Edit</button>                           
            )
        },
       
        sortable: false,
        filterable : false,
        width : 100,
        maxwidth : 100,
        minwidth : 100
    }
    ]

    const colUserLoginHistoryDetails = [{
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}><strong>User</strong></div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "Editor",
        sortable: true,
        filterable : true
       
    },
    {
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            >Login Date</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "DateInFormatted",
        sortable: true
    }
    ]

    const colUserActivityEditHistoryDetails = [{
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}
            ><strong>User</strong></div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "Editor",
        sortable: true,
        filterable : true,
        caseInsensitiveFiltering : true
        
    },
    {
        Header :  () => (
            <div
              style={{
                textAlign:"left"
              }}>Edit Date</div>),
            Cell: row => (
                <div style={{ textAlign: "left" }}>{row.value}</div>
              ),
        accessor : "DateInFormatted",
        sortable: true
    },
        {
            Header :  () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                >Reference</div>),

                // Cell : props => {
                //     return(
                //         <button style={{backgroundColor : "green" , color:"#fefefe"}}
                //         onClick = {() => {                    
                //                this.loadEmptyReferenceReportDetails(props.original.Title) //0 = Current Status                          
                //         }}
                //         > View</button>
                //     )
                // }
           
            Cell: props =><a href={("figuredetails/" + props.original.Idx)}> {(props.original.Reference)} </a>,
                accessor : "AttachmentId",
                sortable: false
    }
//     ,
      
//     {
//         Header :  () => (
//             <div
//             style={{
//                 textAlign:"left"
//             }}>Comment</div>),
//             Cell: row => (
//                 <div style={{ textAlign: "left" }}>{row.value}</div>
//             ),
//         accessor : "FileUploadComment",
//         sortable: true
// }

     ]
 
        return (  
            <div>
                        <div  className="row mb-2 pageheading">  
                        <div className="col-sm-12 btn btn-info">  
                        <h5>  Welcome {localStorage.getItem("userName")}</h5>
                        </div>  

                        <div className="col-sm-12 body">
                            <button type="button" className="btn btn-secondary mr-3 btnpadding float-left" onClick={() => { document.location.href = "/Login"; }}>Logout</button>
                            <button type="button" className="btn btn-primary mr-3 btnpadding" onClick={() => { document.location.href = "/Figures"; }}>Manage References</button> 
                            {/* <button type="button" className="btn btn-info mr-3 btnpadding" onClick={() => { document.location.href = "/Search"; }}>Search References</button>  */}
                            <button type="button" className="btn btn-primary mr-3 btnpadding" style={{display:(this.state.showUsersButton? 'inline':'none')}} onClick={() => { document.location.href = "/Users"; }}>Manage Users</button> 
                            <button type="button" className="btn btn-info mr-3 btnpadding" style={{display:(this.state.showUsersButton? 'inline':'none')}} onClick={() => { document.location.href = "/bibloinfo"; }}>Manage Reference Material</button> 
                            <button type="button" className="btn btn-primary mr-3 btnpadding hidden" onClick={() => { document.location.href = "/bibleReferences"; }}>Bible References</button> 
                            <button type="button" className="btn btn-primary mr-3 btnpadding" style={{display:(this.state.showUsersButton? 'inline':'none')}} onClick={() => { document.location.href = "/ContactList"; }}>Manage Contacts</button> 
                            <button type="button" className="btn btn-warning mr-3 btnpadding" style={{display:(this.state.showUsersButton? 'inline':'none')}} onClick={() => { document.location.href = "/GoodTeachingSearchV2"; }}>Ministry</button> 
                            <button type="button" className="btn btn-warning mr-3 btnpadding" style={{display:(this.state.showNewRegistration? 'inline':'none')}} onClick={() => { document.location.href = "/Users"; }}>New Registration Request!</button> 
                            
                            <div className="col-sm-12 btn btn-info">  
                                <h5 style={{ textAlign : "left"}}>   CUG Database Activity Stats </h5>
                            </div>  
                            <hr/>
                        </div>

                    {/* Report Tables */}
                    
                        {this.state.showUserActivitiesReport ?                   

                        <div className="form-group row col-sm-12">
                                <div className="form-group row col-sm-12">
                            <div className="col-sm-12 body sidepadding" style={{ textAlign : "left"}}> 
                            <form onSubmit={ this.onFormSubmit }>
                                        <strong>From &nbsp;</strong><DatePicker
                                                                selected={ this.state.loginFrom }
                                                                onChange={ this.handleLoginFromChange }
                                                                name="loginFrom"
                                                                dateFormat="dd MMM yyyy"
                                                                className="form-control" />
        
                                                <span className="input-group-addon">&nbsp;&nbsp;</span> <strong>To &nbsp;</strong>
                                                <DatePicker
                                                                selected={ this.state.loginTo }
                                                                onChange={ this.handleLoginToChange }
                                                                name="loginTo"
                                                                dateFormat="dd MMM yyyy"
                                                                className="form-control" />
                                                <span className="input-group-addon"> &nbsp;&nbsp;&nbsp;</span>
                                                <button className="btn btn-primary">Submit</button>
                                            </form> 
                            </div>
                                            
                        </div>
                        
                        <div className="form-group row col-sm-12">
                            <div className="col-sm-5 body sidepadding">                            
                                        
                                    <div className="form-group">
                                    <ReactTable
                                        columns={colUserLoginHistoryDetails}
                                        data= {this.state.userLoginHistoryDetails}
                                                        showPagination={true}                                                
                                                        defaultPageSize={10} 
                                                        
                                                        defaultFilterMethod={this.filterCaseInsensitive}
                                                        >
                                    
                                    </ReactTable>
                                    </div>                
                                
                                    </div>
                                    <div className="col-sm-7 body sidepadding"> 
                            
                                
                            <div className="form-group">
                            <ReactTable
                                columns={colUserActivityEditHistoryDetails}
                                data= {this.state.userActivityEditHistoryDetails}
                                                showPagination={true}                                                
                                                defaultPageSize={10}
                                                defaultFilterMethod={this.filterCaseInsensitive} >
                            
                            </ReactTable>
                            </div>                
                        
                            </div>
                        </div>
                        
                        <div className="form-group row col-sm-12">
                        <div className="col-sm-4 body sidepadding">
                            <ReactTable 
                                        columns={columns}
                                    
                                            data= {this.state.figureStatistics}
                                            showPagination={false}
                                            defaultPageSize={5}>
                                                    
                                        </ReactTable>
                        </div>
                        <div className="col-sm-4 body sidepadding">
                            <ReactTable 
                                        columns={colCurrentStatus}
                                    
                                            data= {this.state.figureCurrentStatus}
                                            showPagination={false}
                                            defaultPageSize={5}>
                                                    
                                        </ReactTable>
                        </div>
                        <div className="col-sm-4 body sidepadding">
                            <ReactTable 
                                        columns={colEmptyReference}
                                    
                                            data= {this.state.figureEmptyReference}
                                            showPagination={false}
                                            defaultPageSize={5}>
                                                    
                                        </ReactTable>
                        </div>
                            
                            {
                                this.state.showDetailTableVisible 
                                ?
                                <div className="col-sm-8 body sidepadding">
                                <br/>
                
                                    <ReactTable 
                                                columns={colFigureReportDetails}                                 
                                                    data= {this.state.figureReportDetails}
                                                    showPagination={true}
                                                    defaultPageSize={10}>
                                                            
                                                </ReactTable>
                                
                                <br/>
                                </div>
                                :
                                <div>

                                </div>
                            }

                            {
                                this.state.showEmptyReferenceDetailTableVisible
                                ?
                                <div className="col-sm-8 body sidepadding">
                                    <br/>
                                    <ReactTable
                                    columns={colEmptyReferenceReportDetails}                                 
                                    data= {this.state.emptyReferenceReportDetails}
                                    showPagination={true}
                                    defaultPageSize={20}
                                        >

                                    </ReactTable>
                                </div>
                                :
                                <div>

                                </div>
                            }
                
                    </div>
                    
                        </div>
                       
                       
                       : <div></div>}
                    
                       
                    </div>          
          
            </div>
             
        );  
    }  
}  
  
export default Dashboard;  