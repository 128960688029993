import React, { Component } from 'react';  
// import './App.css';  
import {   Form } from 'reactstrap'; 

const _url = (true == true) ? 'https://geared4it.net/Api/login/' : 'http://localhost:54444/Api/login/';
//const _url =  'https://geared4it.net/Api/login/';
let _param = window.location.pathname.split("/")[2];

/** class definitions **/
class ClsFigureLinksManager extends React.Component{
    constructor(props){
        super(props);
        this.state = {                   
           linksPosts : [],
           linkFiguresPosts : []
        }
    }

   LinkTitle(event) { this.setState({ LinkTitle : event.target.value})}
            componentDidMount(){
                this.loadFigureLinks();                
            }

            loadFigureLinks(){
                // eslint-disable-next-line no-restricted-globals
               // if(_param == undefined || _param === "undefined" || _param === "") window.location.reload();

                fetch(_url + 'GetFigureLinksByIdx?Idx=' + _param,{
                    method : "GET"
                }).then((response) => response.json())
                  .then(response => {                       
                      let links = response;                                     
                      this.setState({                        
                       linksPosts : links//response
                      })
                  })
           }

           render(){
           
            return(
                <div className="col-sm-2" block="true">
                    <br/><br/><br/><br/>
                <strong>Also:</strong>
                <hr/>
                         {this.state.linksPosts.map(function(link, IDIndex)
                        {                    
                            return( <div>
                                    <ul>                                        
                                        <li> <a href={"/Details/" + link.LinkIdx} >{link.LinkIdxFriendlyName}</a> </li>
                                    </ul>
                            </div> )
                                
                        })}
                </div>
            )
           }

}
//files images component store
class ClsFileManager extends React.Component {
    constructor(props){
        super(props);
        this.state = { FileName : '', FilePath : '', 
        FileComment :'', 
        ThumbNail : '',
        Files : []}
        this.FileName = this.FileName.bind(this);  
        this.FilePath = this.FilePath.bind(this);  
        this.FileComment = this.FileComment.bind(this);  
        this.Files = this.Files.bind(this);
        this.ThumbNail = '';

    }

        FileName(event) {   this.setState({ FileName: event.target.value })  } 
        FilePath(event) {  this.setState({ FilePath: event.target.value }) }     
        FileComment(event) {this.setState({ FileComment: event.target.value }) }
        Files(event) { this.setState({ Files : event.target.value})}  

        state = {
            selectedFile : null
        }        
        componentDidMount(){
            this.loadFigureImages();
            this.setState({
                showAdminButton: (localStorage.getItem("userIsSuperAdmin") !== "true") ? false : true
            })
        }

        //functions
        loadFigureImages(){           
             // eslint-disable-next-line no-restricted-globals   
          //   if(_param == undefined || _param === "undefined" || _param === "") window.location.reload();         
            fetch(_url + 'GetFiles?idx=' + _param , {
                method : "GET"
            }).then((response) => response.json())//get json object
              .then(response => {
              
                  let files = response;
                  this.setState({                                          
                                FileComment : files.FileComment ,
                                Files : files,
                                ThumbNail : files.ThumbNail
                                })
              })
        }

        render(){
            return(
                
                <div className="col-sm-2" style={{paddingLeft:"2em"}}>                                 
                <br/>
                
                      <button type="button" style={{ display:(this.state.showAdminButton ? 'inline':'none')}} 
                    className="btn btn-warning mr-3 float-left" 
                  onClick={() => { this.props.history.push("/Dashboard"); }} 
               >Dashboard</button> 

                     <button type="button" className="btn btn-primary mr-3 btnpadding" 
                     style={{display:(this.state.showAdminButton ? 'inline':'none')}} 
                     onClick={() => { this.props.history.push("/FigureDetails/"+ _param); }}
                     block="true">Edit Reference</button>                     
                     

               
                     <br/>
                    <label><strong> Articles</strong></label> 
                    <hr/>
                         {this.state.Files.map(function(image, imageIndex)
                        {       
                                        
                            return (<div><br/> 
                            <span block="true">
                            <a href={image.ThumbNail} >
                                {
                                
                                (image.FilePath.slice((image.FilePath.lastIndexOf(".") - 1 >>> 0) + 2) != "pdf") &&
                                    <img alt="" key={ imageIndex } src={ image.FilePath } 
                                    style={{width: '60%', height: '60%',padding:'2%'}}/>

                                }
                                
                                        <br/>
                                        {image.FileComment} 
                             </a>
                                </span> 
                            </div> )
                                
                        })}
                </div>
            )
        }
}

//details component store
class Details extends React.Component{
    constructor(props) {
        super(props);

        this.state = { 
            Id : '',  Title: '', 
            Meaning: '',   Body: '' , Category : ''
        }

        this.Title = this.Title.bind(this);  
        this.Meaning = this.Meaning.bind(this);  
        this.Body = this.Body.bind(this); 
        this.Category = this.Category.bind(this); 
    }

    Title(event) {   this.setState({ Title: event.target.value })  } 
    Meaning(event) {  this.setState({ Meaning: event.target.value }) }     
    Body(event) {this.setState({ Body: event.target.value })     }
    Category(event) {this.setState({ Category : event.target.value})}  

      componentDidMount() {               
        this.loadDetails();        
    }
    
    //functions
    loadDetails(){        
            // eslint-disable-next-line no-restricted-globals
            _param = window.location.pathname.split("/")[2];
            //if(_param == undefined || _param === "undefined" || _param === "") window.location.reload();
            
            fetch(_url + 'GetFigureByIdx?idx=' + _param ,{//need service wrapper
                method: "GET"
            }).then((response) => response.json())
            .then(response => {           
                 let figure = response;
                this.setState({
                    Id : figure.Id,
                    Idx : figure.Idx,
                    Title : figure.Title,
                    Meaning : figure.Meaning,
                    Body : figure.Body,
                    Category : figure.CategoryName
                })
            }) 
    }

    render(){
        return(
           
            <Form className="col-sm-12 row">
      <div className="col-sm-12 btn btn-primary" block="true" >  
      <button type="button" style={{marginLeft:"15px"}} className="btn btn-secondary mr-3  float-left" onClick={() => { document.location.href = "/Login"; }}>Logout</button>            
                   <button className="btn btn-success mr-3 float-left"  onClick= {() => { this.props.history.push('/Figures') }} 
                      
                          color="success"  block="true">Back to References</button>
            <strong>Reference Details</strong>
                       
                </div> 
      <ClsFileManager {...this.props}/>  

        <div className="col-sm-8 SearchApp">
 
            <div>

                <div className="form-group row">
                    <label><strong>Title</strong> </label>
                    
                    &nbsp;&nbsp;<label>{this.state.Title || ''} </label> 
                    
                </div>
             
                <div className="form-group row">
                    <label><strong>Meaning</strong> </label>
                    
                    &nbsp;&nbsp;      <label dangerouslySetInnerHTML={{__html: this.state.Meaning}}/>
                
                </div>
                <div className="form-group row">
                    <label ><strong>Category</strong> </label>
                    
                    &nbsp;&nbsp;      <label>{this.state.Category || ''} </label> 
                    
                </div>

                <div className="form-group row">
                    <label><strong>Body</strong> </label>
                    
                    &nbsp;&nbsp;      
                    <p className="bodyWidth" dangerouslySetInnerHTML={{__html: this.state.Body}}>
                    </p>
                                 
                </div>
            </div>
                             
</div>
            <ClsFigureLinksManager {...this.props}/>                
      </Form>
           
        )
    }
}

export default Details