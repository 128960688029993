import React, { Component, useCallback, useState } from "react";
import "./App.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  BrowserRouter as Router,
  useParams,
} from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const _url =  true == true    ? "https://geared4it.net/Api/login/"    : "http://localhost:54444/Api/login/";
class BibleReferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      BibleSearchTerms: "",
      BodyText: "no Bible verse selected",
      displayBibleVerse: false,
      BookOf: "",
      ChapterId: 0,
      VerseId: 0,
      displayBibleFootNote: false,
      CompoKey: "",
      FootNoteVerse: "",
      showAdminButton : false,
      showSubmissionDisabled : false,
      showSubmission : true,
      ResultsFound : ""
    };
  }
  componentDidMount() 
  {
    this.setState({            
      showAdminButton: (localStorage.getItem("userIsSuperAdmin") !== "true") ? false : true
  });

    this.loadBibleReferences();
    let _param = window.location.href.substring(window.location.href.indexOf('?') + 1);
    
    if(_param.length > 0 && !_param.includes("bibleReferences"))
    {
    this.loadFootNoteContent(_param);
    }
  }
  ReloadBibleReferences = (e) => {
    this.state.BibleSearchTerms = undefined;
    this.state.displayBibleVerse = false;
    this.state.displayBibleFootNote = false;
    this.loadBibleReferences();
  };
  loadBibleReferences = (e) => {
    // e.preventDefault();

    this.setState({
      showSubmissionDisabled: false,
      showSubmission : false,
      ResultsFound : ""
    })


    this.state.BibleSearchTerms =
      (this.state.BibleSearchTerms == "")    ? undefined  : this.state.BibleSearchTerms;
    fetch(
      _url + `BibleReferences?searchFilter=${this.state.BibleSearchTerms}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((posts) => {

        this.state.showSubmissionDisabled = true; 
            this.state.showSubmission = true; 
            this.state.ResultsFound = "Total Records found: " + posts.length

        this.setState({ posts: posts });
      });
  };
  loadFootNoteContent = (param) => {
      //do your task here before redirect
      var Url = _url + "BibleFootNoteContent?Idx=" + param ; //"ge_18:14"; 
  
      fetch(Url, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          if (response != null) {
           
            this.setState({ FootNoteVerse: response.Content });
            this.setState({ displayBibleFootNote: true });
            this.setState({ displayBibleVerse: true });
          } else {
            this.setState({ displayBibleFootNote: false });
          }
        });
  
  }
  DisplayBibleReferences(props) {
    this.setState({ BodyText: props.original.BodyText });
    this.setState({ BookOf: props.original.BookOf });
    this.setState({ ChapterId: props.original.ChapterId });
    this.setState({ VerseId: props.original.VerseId });
    this.setState({ CompoKey: props.original.CompoKey });
    this.toggleOnClick(false);
    this.toggleDisplayBibleFootNote(props.original.CompoKey);
  }
  toggleOnClick(showBibleReference) {
    this.setState({ displayBibleVerse: !showBibleReference });
  }
  toggleDisplayBibleFootNote(key) {
    var fullUrl = _url + "BibleFootNote?compoKey=" + key;
    fetch(fullUrl, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response != null) {
          //show footnote
          this.setState({ FootNoteVerse: response.Content });
          this.setState({ displayBibleVerse: true });
          this.setState({ displayBibleFootNote: true });
          
        } else {
          //this.setState({ displayBibleVerse: false });
          this.setState({ displayBibleFootNote: false });
        }
      });
  }
  changeBibleSearchTerms = (e) => {
    this.setState({
      BibleSearchTerms: e.target.value,
    });
  };
  handleClick = (e) => {
   
    e.preventDefault();
    console.log("The link was clicked.");
  };

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== 'undefined') {
        // filter by text in the table or if it's a object, filter by key
        if (typeof content === 'object' && content !== null && content.key) {
            return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
        } else {
            return String(content).toLowerCase().includes(filter.value.toLowerCase());
        }
    }

    return true;
};

contentClickHandler = (e) => {
  const targetLink = e.target.closest('a');
  if(!targetLink) return;
  e.preventDefault();
  
  console.log(targetLink.href); // this.props.history.push(e.target.href)
};
  render() {
    if( this.state.showSubmissionDisabled === true){     
      this.state.showSubmission = true;   
    }

    const columns = [
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Book
          </div>
        ),
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.value}</div>,
        accessor: "BookOf",
        sortable: true,
        filterable: true,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Chapter
          </div>
        ),
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.value}</div>,
        accessor: "ChapterId",
        sortable: true,
        filterable: true,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Verse
          </div>
        ),
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.value}</div>,
        accessor: "VerseId",
        sortable: true,
        filterable: true,
      },
      {
        Header: "Actions",
        Cell: (props) => {
          return (
            <button
              style={{ backgroundColor: "green", color: "#fefefe" }}
              onClick={() => {
                this.DisplayBibleReferences(props);
              }}
            >
              View
            </button>
          );
        },
        sortable: false,
        filterable: false,
        width: 70,
        maxwidth: 70,
        minwidth: 70,
      },
    ];
    //do return
    return (
      <div className="row sm-2 pageheading">
        <div className="col-sm-12 btn btn-primary sidepadding" style={{marginLeft:"15px",marginBottom:"15px"}}>  
             
                    <button type="button" style={{ display:(this.state.showAdminButton ? 'inline':'none')}} 
                    className="btn btn-warning mr-3 float-left" 
                    onClick={() => { this.props.history.push("/Dashboard"); }} >Dashboard</button> 
                   
                   
                     <button type="button" className="btn btn-success mr-3 float-left" onClick={() => { this.props.history.push("/Figures"); }}>References</button>                    
                    <strong style={{fontSize:"x-large"}}> Bible References </strong>
        </div>  

       <div className="row col-sm-12">  


          <div className="col-sm-6 body sidepadding">
            <div className="row">
                <div className="col-sm-8">
                    <input type="text" value={this.state.BibleSearchTerms || ''}
                            onChange={this.changeBibleSearchTerms}   
                            placeholder='Enter keywords'
                            className="form-control" />
              </div>
            
              <div className="col-sm-4 pull-left"   style={{ marginTop: "5px" }}>
                <button className="btn btn-primary float-left" onClick={this.loadBibleReferences}>
                    {this.state.showSubmission ? "Search" : "Processing..."}
                </button> 
              </div>
              <div className="col-sm-8 text-right">
                   <strong className="btn btn-success"> {this.state.ResultsFound }</strong>
              </div>
            </div>
           
           


            <br />
            <ReactTable
              columns={columns}
              defaultFilterMethod={this.filterCaseInsensitive}
              noDataText="Loading..."
              data={this.state.posts}
              filterable
              defaultPageSize={10}
            ></ReactTable>
          </div>
          <div className="col-sm-6 body sidepadding">
            {this.state.displayBibleVerse ? (
              <div>
                <div className="panel panel-default"></div>
               <br></br>
                <div className="container">
                  <h2>
                    {this.state.BookOf} Chapter {this.state.ChapterId} verse{" "}  {this.state.VerseId}
                  </h2>
                  <div className="panel panel-default">
                    <p disabled
                      className="bodyWidth"
                      style={{
                        fontSize: "larger",
                        border: "solid thin",
                        padding: "1em",
                      }}
                      onClick={this.contentClickHandler}
                      dangerouslySetInnerHTML={{ __html: this.state.BodyText }}
                    ></p>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {this.state.displayBibleFootNote ? (
              <ClsBibleFootNoteContent {...this.state} />
            ) : (
              
              <div></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
class ClsBibleFootNoteContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayBibleFootNote: true,
      CompoKey: "",
      FootNoteVerse: props.FootNoteVerse,
      showModal: false,
      modalContent: "footnote link component",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (idx) => {
 
    //do your task here before redirect
    var fullUrl = _url + "BibleFootNoteContent?Idx=" + idx ; //"ge_18:14"; 
    fetch(fullUrl, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response != null) {
          // replace each anchor elements with  
          //  <FootNoteLink Idx="ge_18:14" Text={this.state.modalContent} ></FootNoteLink>
          var verseContent = response.Content;
          // update modal content and toggle modal
          this.setState({ modalContent: verseContent });
          this.toggleModal();
        } else {
          this.setState({ displayBibleFootNote: false });
        }
      });
  };
  toggleModal = () => this.setState({ showModal: !this.state.showModal });

  contentClickHandler = (e) => {
    const targetLink = e.target.closest('a');
    if(!targetLink) return;
    e.preventDefault();
    
    console.log(targetLink.href); // this.props.history.push(e.target.href)
  };

  render() {
    return (
      <>
        <Router>
          <div className="container hidden" id="footNote">
            <h2>Footnotes</h2>
            <div className="panel panel-default">
              <p
                className="bodyWidth"
                style={{
                  fontSize: "larger",
                  border: "solid thin",
                  padding: "1em",
                }}
                onClick={this.contentClickHandler}
                dangerouslySetInnerHTML={{ __html: this.state.FootNoteVerse }}
              ></p>
            </div>
          </div>
        </Router>
       
        <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Verse</ModalHeader>
          <ModalBody 
          dangerouslySetInnerHTML={{ __html: this.state.modalContent}}>
            </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}


export default BibleReferences;