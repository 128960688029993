import React, { Component } from 'react';  
import {  } from 'reactstrap';  
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'; 
import { Button} from 'reactstrap';  
import './App.css'; 


//const _url = (true == true) ? 'https://cugonlinewebapi.azurewebsites.net/Api/' : 'http://localhost:54444/Api/';
const _url = (true == true) ? 'https://geared4it.net/Api/' : 'http://localhost:54444/Api/';
var _isFullSearch = "false";
class Search extends Component {  

  constructor(props){
    super(props);

    let expiryDate = new Date();

        var day = expiryDate.getDate();
        var month = expiryDate.getMonth();
        var year = expiryDate.getFullYear();
        var expiryDateString = day + 'Fsaerch-' + month + '-' + year;

        if (expiryDateString != localStorage.getItem("expiryDate") || localStorage.getItem("userId") == "") {
            
            localStorage.setItem("userName", "")
            localStorage.setItem("userCategoryRole", "")
            localStorage.setItem("userIsAdmin", "")//isEdit
            localStorage.setItem("userId", "")//is user
            localStorage.setItem("userIsSuperAdmin","")//is esuper admin            
            localStorage.setItem("expiryDate","");            
          
            this.props.history.push("/Login"); 
           // return;
        }

    this.state = {
      wikiSearchReturnValues: [],
      showSubmission : true,
      wikiSearchTerms: '',
      showSubmissionDisabled : false,
      //TitleResults : '',
      isFullSearch: true, //default set...
      wikiFullSearchReturnValues: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  useWikiSearchEngine = (e) => {
    e.preventDefault();

    this.setState({ 
      wikiSearchReturnValues: [],
      showSubmission : false,
      showSubmissionDisabled: false,
      wikiFullSearchReturnValues : []
    });

    const pointerToThis = this;
    
    _isFullSearch = this.state.isFullSearch;
    var userCategoryRole = localStorage.getItem("userCategoryRole");
    fetch(_url + `Wiki/GetSearchResultsBy?filter=${this.state.WikiSearchTerms}&searchRole=${userCategoryRole}&isFullSearch=false&searchCount=1` )
      .then(
        function (response) {
          return response.json();//json stringyfy
        }
      )
      .then(
        function (response) {     
                         
           pointerToThis.state.showSubmissionDisabled = true;

          for (var key in response) {
            pointerToThis.state.wikiSearchReturnValues.push({
              queryResultPageFullURL:  `/Details/${response[key].Idx}`,// 'no link',
              queryResultPageID: response[key].Idx,
              queryResultPageTitle: response[key].Title,
              queryResultPageSnippet: response[key].Meaning,
              queryResultPageCatergory: response[key].CategoryName
            });
          }
          pointerToThis.forceUpdate();
        }
      )
      
      .then(
        
        fetch(_url + `Wiki/GetSearchResultsBy?filter=${this.state.WikiSearchTerms}&searchRole=${userCategoryRole}&isFullSearch=${_isFullSearch}&searchCount=2` )
        .then(
          function(response){
            return response.json();
          }
        )
        .then(function(response){
          pointerToThis.state.showSubmissionDisabled = true;

          for (var key in response) {
            pointerToThis.state.wikiFullSearchReturnValues.push({
              queryResultPageFullURL:  `/Details/${response[key].Idx}`,// 'no link',
              queryResultPageID: response[key].Idx,
              queryResultPageTitle: response[key].Title,
              queryResultPageSnippet: response[key].Meaning,
              queryResultPageCatergory : response[key].CategoryName
            });
          }
          pointerToThis.forceUpdate();
        })
      )      
  }
  changeWikiSearchTerms = (e) => {
    this.setState({
      WikiSearchTerms: e.target.value
    });
  }

  componentDidMount()
    {       
        this.setState({
            showButton: (localStorage.getItem("userIsAdmin") !== true) ? false : true
        })
    }

    render() {
      let wikiSearchResults = [];

      let wikiFullSearchResults = [];

      let bodyLabel = "";
      let titleLabel = ""

      if(this.state.wikiSearchReturnValues.length > 0){
        titleLabel = "Title Results";
      }
      
      this.state.wikiSearchReturnValues.forEach((item,idx) => { 
            
        wikiSearchResults.push(          
          <div className="col-md py-3" key={idx}>
              <div className="card">
                <a className="btn" href={item.queryResultPageFullURL} >
                {item.queryResultPageTitle} <small> ({item.queryResultPageCatergory}) </small></a>  
                  <a  href={item.queryResultPageFullURL} className="link"  
                  style={{padding:'1%'}}
                  dangerouslySetInnerHTML={{__html: item.queryResultPageSnippet}}>                        
                  </a>
            </div>   
            </div>
        )
        // force wrap to next row every 4 columns
        if ((idx+1)%4===0) {          
          wikiSearchResults.push(<div className="w-100" key={null}></div>)
        }       
      })

      if(this.state.wikiFullSearchReturnValues.length > 0){
        bodyLabel = "Body Results";
      }
      
      this.state.wikiFullSearchReturnValues.forEach((item,idxFull) => {       
        wikiFullSearchResults.push(
          
          <div className="col-md py-3" key={idxFull}>
              <div className="card">
                <a className="btn" href={item.queryResultPageFullURL}  >
                {item.queryResultPageTitle} <small> ({item.queryResultPageCatergory}) </small> </a>  
                  <a   href={item.queryResultPageFullURL} className="link"  
                  style={{padding:'1%'}}
                  dangerouslySetInnerHTML={{__html: item.queryResultPageSnippet}}>                        
                  </a>
              </div>   
            </div>
        )
        // force wrap to next row every 4 columns
        if ((idxFull+1)%4===0) {          
          wikiFullSearchResults.push(<div className="w-100" key={null}></div>)
        }       
      })
   
      if( this.state.showSubmissionDisabled === true){     
        this.state.showSubmission = true;   
      }

        return(
            <Router>
              <div className="col-sm-12 body">             
                    <button type="button" className="btn btn-primary mr-3 btnpadding" style={{display:(this.state.showButton? 'inline':'none')}} onClick={() => { this.props.history.push("/Dashboard"); }}>Dashboard</button>                     
                </div>
           <div className="SearchApp" style={{textAlign:"center"}}>
              {/* <h2>CUG Search Portal</h2>               */}
              <div className="row">
                <div className="col-md py-12">
                  <form action="">
                    <input type="text" value={this.state.WikiSearchTerms || ''} onChange={this.changeWikiSearchTerms}   placeholder='Search  References' />
                    <button ref="btn" type='submit' onClick={this.useWikiSearchEngine}>
                      {this.state.showSubmission ? "Search" : "Loading References..."}</button>
                  </form>
                 </div> 
              </div>
              
              <div className="row">              
                <div className="col-sm-12 btn btn-primary">  
                   <h5> {titleLabel} </h5>
                 </div>
              </div>
              
              <div className="row">
              {wikiSearchResults}
              </div>
              
              <div className="row">              
                <div className="col-sm-12 btn btn-success">  
                  <h5> {bodyLabel} </h5>
                 </div>
              </div>

             
              <div className="row">            
                {wikiFullSearchResults}            
              </div>
              
            </div>

        <Switch>
        <Route path='/Details/:id'  /> 
        </Switch>
      </Router> 
        )
    }
}


export default Search;  