import React, { Component, useCallback, useState } from "react";
import "./App.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
    BrowserRouter as Router,
    useParams,
  } from "react-router-dom";

  var _bibleId = "";
  const _url =  (true == true)    ? "https://geared4it.net/Api/wiki/"    : "https://localhost:44344/api/wiki/";
  import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

  class GoodTeachingBible extends Component{
    constructor(props) 
    {
        super(props);
        this.state = {
            posts:[],
            figureReportDetails:[],
            bibleBookDetails:[],
            bibleBookVersusDetails:[],
            Data : {
                List : [],
                ResultsFound : ""
            }
        };        
    };

    componentDidMount(){         
       this.loadBibleLists();
    }

    loadBibleLists = () => {
       //  e.preventDefault();
      // eslint-disable-next-line no-restricted-globals

      this.setState({
        ResultsFound : ""
      })

      fetch(_url + "getbibles",{
        method: "GET"
      }).then(response => response.json())
      .then(posts => {
        this.setState({
            posts:posts.data
        });
      })
    }

    loadBibleSelected(bibleId , field){
        // eslint-disable-next-line no-restricted-globals
        _bibleId = bibleId;
        fetch(_url + 'GetBibleSelected?bibleId='+ bibleId +'&abbreviation=' + field,{
            method : "GET"
        }).then(
            (response) => response.json()
        ).then(response => {
               this.setState({
                   figureReportDetails : response.data
               })

               //this.toggleOnClick(false);
        });
   }

   loadBibleChapterSelected(book){
    // eslint-disable-next-line no-restricted-globals
    fetch(_url + 'GetBibleBooksSelected?bibleId='+ _bibleId +'&abbreviation=' + book,{
        method : "GET"
    }).then(
        (response) => response.json()
    ).then(response => {
           this.setState({
            bibleBookDetails : response.data.chapters
           })

          // this.toggleOnClick(false);
    });
}

    loadBibleVerseSelected(bibleId,chapter)
    {
        // eslint-disable-next-line no-restricted-globals
        fetch(_url + 'GetBibleChapterVersesSelected?bibleId='+ bibleId +'&chapter=' + chapter,{
            method : "GET"
        }).then(
            (response) => response.json()
        ).then(response => {
            this.setState({
                bibleBookVersusDetails : response.data
            })

            // this.toggleOnClick(false);
        });
    }

   filterCaseInsensitive = (filter, row) => 
   {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

        return true;
   };
     render()
     {

        const columns = [
            {
                Header :  () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Id</div>),
                    Cell: row => (
                        <div style={{ textAlign: "left" }}>{row.value}</div>
                      ),
                accessor : "id",
                sortable: true, 
                width : 40
            },
            {
                Header :  () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Abbreviation</div>),
                    Cell: row => (
                        <div style={{ textAlign: "left",backgroundColor:"lightgray" }}>{row.value}</div>
                      ),
                accessor : "abbreviation",
                sortable: true,
                filterable : true,
                width : 120
            },            
            {
                Header :  () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Name</div>),
                    Cell: row => (
                        <div style={{ textAlign: "left",backgroundColor:"lightgray" }}>{row.value}</div>
                      ),
                accessor : "name",
                sortable: true,
                filterable : true
            },
            {
                Header :  () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Description</div>),
                    Cell: row => (
                        <div style={{ textAlign: "left",backgroundColor:"lightgray" }}>{row.value}</div>
                      ),
                accessor : "description",
                sortable: true,
                filterable : true
            },
            {
                Header :  "Actions",
                Cell : props =>{
                    return(
                        <button style={{backgroundColor: "green" , color:"#fefefe"}}
                        onClick = {() => {                         
                            this.loadBibleSelected(props.original.id, props.original.abbreviation) 
                        }}
                        >View</button>                           
                    )
                },
            
                sortable: false,
                filterable : false,
                width : 100,
                maxwidth : 100,
                minwidth : 100
            } 
        ]

        const selectedBibleBook = [
            {
                Header :  () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Id</div>),
                    Cell: row => (
                        <div style={{ textAlign: "left" }}>{row.value}</div>
                      ),
                accessor : "id",
                sortable: true, 
                width : 40,
                maxwidth : 40,
                minwidth : 40
            },
            {
                Header :  () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Book</div>),
                    Cell: row => (
                        <div style={{ textAlign: "left",backgroundColor:"lightgray" }}>{row.value}</div>
                      ),
                accessor : "name",
                sortable: true,
                filterable : true 
            },{
                Header :  "Actions",
                Cell : props =>{
                    return(
                        <button style={{backgroundColor: "green" , color:"#fefefe"}}
                        onClick = {() => {                         
                            this.loadBibleChapterSelected(props.original.id) 
                        }}
                        >View</button>                           
                    )
                },
            
                sortable: false,
                filterable : false,
                width : 70
            } 
        ]

        const selectedBibleBookChapters = [
            {
                Header :  () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Chapter</div>),
                    Cell: row => (
                        <div style={{ textAlign: "left",backgroundColor:"lightgray" }}>{row.value}</div>
                      ),
                accessor : "id",
                sortable: true,
                filterable : true 
            },{
                        Header :  "Actions",
                        Cell : props =>{
                            return(
                                <button style={{backgroundColor: "green" , color:"#fefefe"}}
                                onClick = {() => {                         
                                    this.loadBibleVerseSelected(props.original.bibleId, props.original.id) 
                                }}
                                >View</button>                           
                            )
                        },
                    
                        sortable: false,
                        filterable : false,
                        width : 70
                    } 
        ]
        return( 
                    <div className="row"> 
                        <div className="col-sm-12 btn btn-primary" > 
                            <button type="button" style={{marginLeft:"15px"}} className="btn btn-secondary mr-3  float-left" 
                                onClick={() => { document.location.href = "/Login"; }}>Logout</button>

                        <button type="button" style={{marginLeft:"15px",display:"inline"}} className="btn btn-warning mr-3 float-left" 
                            onClick={() => { this.props.history.push("/Dashboard"); }}
                            >Dashboard</button> 
                    
                        
                        <button className="btn btn-success mr-3 float-right"  onClick= {() => { this.props.history.push('/FigureDetails/0') }} 
                        style={{display:(this.state.showButton? 'inline':'none')}}
                            color="success"  block="true">Add bible</button>  
                        </div> 

                        <div className="row col-sm-12">
                            <div className="col-sm-12 body sidepadding">
                            <br/>
                                <ReactTable 
                                    columns={columns}
                                    data= {this.state.posts}
                                    filterable
                                    defaultPageSize={10} 
                                    noDataText="Bibles List"
                                    defaultFilterMethod={this.filterCaseInsensitive}>                                        
                                </ReactTable>
                            </div>
                        </div> 

                        <div className="col-sm-6 body sidepadding">
                            <ReactTable 
                                        columns={selectedBibleBook}                                    
                                            data= {this.state.figureReportDetails}
                                            filterable
                                            defaultPageSize={10}
                                            noDataText="Bible Books List"
                                            defaultFilterMethod={this.filterCaseInsensitive}>
                                                    
                            </ReactTable>
                        </div>

                        <div className="col-sm-6 body sidepadding">
                            <ReactTable 
                                        columns={selectedBibleBookChapters}                                    
                                            data= {this.state.bibleBookDetails}
                                            filterable
                                            defaultPageSize={10}
                                            noDataText="Bible Chapters List"
                                            defaultFilterMethod={this.filterCaseInsensitive}>
                                                    
                            </ReactTable>
                        </div>

                        <div className="row col-sm-12">
                            <div className="body sidepadding">
                                <label><strong>{this.state.bibleBookVersusDetails.reference}</strong> </label>
                                
                                    
                                <p style={{fontSize:22}}
                                 className="bodyWidth" dangerouslySetInnerHTML={{__html: this.state.bibleBookVersusDetails.content}}>
                                </p>
                                    
                           </div> 
                        </div>
                    </div>
                    
                )
        }

  }

  export default GoodTeachingBible;