import React, { Component } from "react";
import "./App.css";
import { Button, Form, Input, InputGroup } from "reactstrap";
import ReactTable from "react-table";
import Select from "react-select";
//
const _url =
  true == false
    ? "https://geared4it.net/Api/login/"
    : "http://localhost:54444/Api/login/";
let _param = window.location.pathname.split("/")[2];

//if (_param === "undefined") window.location.reload();

class FootNoteContentManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
   
        FootNoteVerse: "",
      };
  }

  componentDidMount() {
    // debugger;
    //set state
    this.getFootNoteContent();
  }

  //functions
  getFootNoteContent() {
    // eslint-disable-next-line no-restricted-globals
     var param = window.location.href;
debugger;
    //do your task here before redirect
    var fullUrl = _url + "BibleFootNoteContent?Idx=" + _param; //"ge_18:14";

    fetch(fullUrl, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response != null) {
          // replace each anchor elements with
          //  <FootNoteLink Idx="ge_18:14" Text={this.state.modalContent} ></FootNoteLink>
          var verseContent = response.Content;

          // update modal content and toggle modal
           this.setState({FootNoteVerse : response.Content})
        } else {
       
        }
      });
  }

  

  render() {
    return (
      <div>
          this {_param};{this.state.FootNoteVerse}
      </div>
    );
  }
}


export default FootNoteContentManager;
