import React, { Component } from 'react';  
import './App.css';  
import ReactTable   from 'react-table';
import "react-table/react-table.css";
import { Button,  Form } from 'reactstrap'; 
import Select from 'react-select'; 

const initialState = {
    users : [],
    Id : 0,
    Firstname : '',
    Surname : '',
    Username : '',
    Password : '',            
    CategoryName : '', //selected value
    Email : '',
    editUserVisible: false ,            
    IsEditor : '0',
    FirstnameError : '',
    SurnameError : '',
    UsernameError : '',
    PasswordError : '',
    EmailError : ''
}
const _url = (true == true) ? 'https://geared4it.net/Api/login/' : 'http://localhost:54444/Api/login/';

const categoryList = [
    { value: 'All', label: 'All' },
    { value: 'Radiation', label: 'Radiation' },
    { value: 'Aqua', label: 'Aqua' },
    { value: 'Biblopedia' , label : 'Biblopedia'}
  ];

 class Users extends Component {  
    constructor(props){
        super(props);

        this.state = initialState;

        this.Firstname = this.Firstname.bind(this);
        this.Surname = this.Surname.bind(this);
        this.Username = this.Username.bind(this);
        this.Password = this.Password.bind(this);
        this.CategoryName = this.CategoryName.bind(this);
        this.Email = this.Email.bind(this);
        this.IsEditor = this.IsEditor.bind(this);
        this.IsSuperAdmin = this.IsSuperAdmin.bind(this);

        this.saveUserDetails = this.saveUserDetails.bind(this)// wiring ... 15 min
        this.handleChange = this.handleChange.bind(this);
    }

    Firstname(event) { this.setState({ Firstname: event.target.value})}
    Surname(event) { this.setState({ Surname: event.target.value})}
    Username(event) { this.setState({ Username: event.target.value})}
    Password(event) { this.setState({ Password: event.target.value})}
    CategoryName(event) { this.setState({ CategoryName: event.target.value})}
    Email(event) { this.setState({ Email: event.target.value})}
    
    IsEditor(event) 
    {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name] : value})
    }
    IsSuperAdmin(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({  [name] : value})
    }

    handleChange (categoryList) {     
        this.setState({CategoryName : categoryList.label });                
      }

    componentDidMount()
    {       
        this.loadUsers();

    }

    loadUsers = () => {
         // eslint-disable-next-line no-restricted-globals
         this.toggleOnClick(true);
        fetch(_url + `SystemUsers`,{
            method: "GET"
        }).then(response => response.json())
          .then(users => {                   
              this.setState({users:users})              
          });
    }

    toggleOnClick(isHidden) {
        this.setState({editUserVisible: !isHidden});
      }

      DeleteUser(userId){
          // eslint-disable-next-line no-restricted-globals
        
        fetch(_url + 'DeleteUserById?id=' + userId,{
            method : "GET"
        }).then(
            (response) => response.json()
        ).then(response => {
            this.loadUsers(); 
        })
      }
      loadEditUser(userId){
        // eslint-disable-next-line no-restricted-globals
        
        fetch(_url + 'GetSystemUserById?id=' + userId,{
            method : "GET"
        }).then(
            (response) => response.json()
        ).then(response => {
            let userInfo = response;

            this.setState({
                Id : userInfo.Id,
                Firstname : userInfo.Name,
                Surname : userInfo.Surname,
                Username : userInfo.UserName,
                Password : userInfo.Password,
                CategoryName : userInfo.CategoryName,
                Email : userInfo.Email,
                IsEditor : userInfo.IsEditor,
                IsSuperAdmin : userInfo.IsSuperAdmin
            })

            this.toggleOnClick(false);
        })
    }

    validate = () => {

        let FirstnameError = ""
        let SurnameError = ""
        let UsernameError = ""
        let PasswordError = ""
        let EmailError = "";
    
        if(!this.state.Email.includes("@")){
            EmailError = "invalid Email";
        }

        if(EmailError){
            this.setState({ EmailError});
            return false;
        }

        if(!this.state.Firstname) FirstnameError = "First name cannot be blank";
        if(!this.state.Surname) SurnameError= "Surname cannot be blank";
        if(!this.state.Username) UsernameError = "User name cannot be blank";
        if(!this.state.Password) PasswordError = "Password cannot be blank";

        if(EmailError || FirstnameError ||  SurnameError || UsernameError || PasswordError){
            this.setState({FirstnameError , SurnameError , UsernameError , PasswordError , EmailError});
            return false;
        }

        return true;
    }
    saveUserDetails(event){        
        event.preventDefault();

        const isValid = this.validate();
        if(isValid){
            this.setState(initialState);

            fetch(_url + 'EditSystemUser',{
                method : 'post',
                headers:{
                    'Accept': 'application/json',  
                    'Content-Type': 'application/json' 
                  },
                  body : JSON.stringify({
                      ID : this.state.Id,
                      Name : this.state.Firstname,
                      Surname : this.state.Surname,
                      UserName : this.state.Username,
                      Password : this.state.Password,
                      Email : this.state.Email,
                      CategoryN : this.state.CategoryName,
                      nKey : this.state.IsEditor,
                      IsSuperAdmin : this.state.IsSuperAdmin
                  })
            }).then((response) => response.json())
              .then((result) => {
                  if(result.Status === 'Success'){
                    this.loadUsers();                
                  }else{
                    alert(' Error saving Details!')
                  }
              })
        }
       
       
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }
    
        return true;
    };

    render() {  

        const columns = [
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}>Name </div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.Name}
                    </div>
            ),
            accessor : "Name",
            sortable: true,
            filterable : true
        },
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}>Surname</div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.Surname}
                    </div>
            ),
            accessor : "Surname",
            sortable: true,
            filterable : true
        },
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}>Username</div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.UserName}
                    </div>
            ),
            accessor : "UserName",
            sortable: true
        },
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}>Password</div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.Password}
                    </div>
            ),
            sortable: false
        },
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}>Email</div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.Email}
                    </div>
            ),
            accessor : "Email",
            sortable: false,
            width : 170
        },
        {
            Header : () => (               
                    <div style={{ textAlign : "left"}}>Category</div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.CategoryName}
                    </div>
            ),
            accessor : "CategoryName",
            sortable: false
        },        
        {
            Header : "Actions",
            Cell : props => {
                return(
                    <div>
                        <button style={{backgroundColor : "orange" , color:"#fefefe"}}
                              onClick = {() => { this.loadEditUser(props.original.Id) }}> Edit </button>
                             &nbsp; &nbsp;&nbsp; 
        
                        <button style={{backgroundColor : props.original.IsDeleted ? "red" : "green" , color:"#fefefe"}}
                        onClick = {() => {  this.DeleteUser(props.original.Id)    }}   >{props.original.IsDeleted ? "Activate" : "Deactivate"} </button>
                    </div>
                    
                )
            },
            sortable: false,
            filterable : false,
            width : 165
        }
    ]

        return (  
            <div>

           
            <div  className="row mb-2 pageheading">  
                <div className="col-sm-12 btn btn-primary">  
                  <h5 onClick= {() => { this.props.history.push("/Dashboard"); }}>  Manage Users    </h5>
                </div>                
            </div> 
            <div className="row">  
                <div className="col-sm-8">
                    <div className="body sidepadding">
                    
                           
                    <button type="button" className="btn btn-primary mr-3 btnpadding float-left"  onClick={() => { this.props.history.push("/Dashboard"); }}>Dashboard</button>                     
            

                    <button className="btn btn-success mr-3 btnpadding"  
                    onClick={() => this.toggleOnClick(false)}                
                    color="success">Add User</button>
                    

                    <ReactTable columns={columns}
                     defaultFilterMethod={this.filterCaseInsensitive}
                    noDataText="loading"
                        data= {this.state.users}
                        filterable
                        defaultPageSize={15} >                                
                    </ReactTable>
                    </div>
                </div> 

                <div className="col-sm-4">   
                    <div className="body sidepadding">
                        <br/>
                  
    
                    <br/>
                    {
                        this.state.editUserVisible
                            ? 
                           
                            <div className="col-sm-12 force-left">
                 <Form>
                    <br/>
                 <div className="form-group">
                 <strong>First Name</strong>
                    <input type="text" className="form-control" id="firstName"
                    value={this.state.Firstname || ''} onChange={this.Firstname.bind(this)} 
                    aria-describedby="firstName" placeholder="First Name"/>
                    <div style={{fontSize:'10', color: 'red'}}>{this.state.FirstnameError}</div>
                 </div>
                 

                 <div className="form-group">
                 <strong>Surname</strong>
                    <input type="text" className="form-control" id="surname" 
                     value={this.state.Surname || ''} onChange={this.Surname.bind(this)} 
                    aria-describedby="surname" placeholder="Surname"/>
                       <div style={{fontSize:'10', color: 'red'}}>{this.state.SurnameError}</div>
                 </div>
              


                 <div className="form-group">
                 <strong>Username</strong>
                    <input type="text" className="form-control" 
                    id="username"   name="username"
                      value={this.state.Username || ''} 
                      onChange={this.Username.bind(this)} 
                    aria-describedby="username" placeholder="Username"/>
                    <div style={{fontSize:'10', color: 'red'}}>{this.state.UsernameError}</div>
                 </div>
                 


                 <div className="form-group">
                 <strong>Password</strong>
                    <input type="text" className="form-control" id="password" 
                          value={this.state.Password || ''} onChange={this.Password.bind(this)} 
                    aria-describedby="password" placeholder="Password"/>
                         <div style={{fontSize:'10', color: 'red'}}>{this.state.PasswordError}</div>
                 </div>
            
                 <div className="form-group">
                 <strong>Category</strong>                   
                    <Select         
                        defaultValue={categoryList.filter(categoryList => categoryList.label === this.state.CategoryName)}
                        onChange={this.handleChange}
                        options={categoryList} 
                        placeholder='Select Category Group'/>
                 </div> 

                 <div className="form-group">
                 <strong>Email</strong>
                    <input type="text" className="form-control" id="email" 
                      value={this.state.Email || ''} onChange={this.Email.bind(this)} 
                    aria-describedby="email" placeholder="Email"/>
                     <div style={{fontSize:'10', color: 'red'}}>{this.state.EmailError}</div>
                 </div> 

                <div className="form-group">
                    <label>
                    <input
                            checked={this.state.IsEditor} 
                            onChange={this.IsEditor.bind(this)} 
                            name="IsEditor"
                            type="checkbox"
                            />
                    <strong>  Editor &nbsp;  </strong> 
                    </label>                   
                </div>

                <div className="form-group">
                <label>
                <input
                        checked={this.state.IsSuperAdmin} 
                        onChange={this.IsSuperAdmin.bind(this)} 
                        name="IsSuperAdmin"
                        type="checkbox"
                         />
                <strong> Super Admin  &nbsp;</strong> 
                </label>                   
                </div>

                 <div className="col-sm-12">
                        <Button className="col-sm-3 float-right"   
                        onClick={this.saveUserDetails} color="success">Save User</Button>  
                    </div> 
                 </Form>
            </div>
                        
                    : <div>
                        
                    </div>
            }

 
                    </div> 
            </div>

            </div>
           
            </div>
            
        );  
    }  
} 
  
export default Users;  