import React, { Component } from 'react';  

import './assets/css/theme-1.css';
import './assets/plugins/prism/prism.css'
import './assets/plugins/bootstrap/css/bootstrap.min.css';
import './assets/fontawesome/js/all';
import { BrowserRouter as Router } from 'react-router-dom';   
// import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
    class Home extends Component {

        render(){
            return (  
                <Router> 
               
                    <body data-spy="scroll" data-target="#navbar" data-offset="72" className="position-relative" >
                       
                        <header style={{backgroundColor : '#007bff'}} className="fixed-top page-header" >
                            <div className="container-fluid container-fluid-max">
                            <nav id="navbar" className="navbar navbar-expand-lg navbar-dark">
                            <a className="navbar-brand" href="#home"> CUG Online </a>
                         
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse justify-content-lg-between" id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#process"> About </a>
                                        </li>
                                       
                                        <li className="nav-item">
                                             <a className="nav-link" href="/login">Login</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" href="#" href="/Register">Register</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" href="#" href="/bibleReferences">Bible Reference</a>
                                        </li>

                                      
                                    </ul>
                                    <div className="form">
                                        <a href="tel:1234567890" className="mr-2 text-white">
                                        <i className="fas fa-phone"></i>
                                        <div className="d-none d-xl-inline"> +27 82 381 6828  </div>
                                        </a>
                                        <a href="mailto:davidrallen1942@gmail.com" className="mr-2 text-white">
                                        <i className="fas fa-envelope"> </i>
                                        <div className="d-none d-xl-inline"> info@cugonline.co.za </div>
                                        </a>
                                    </div>
                                </div>
                            </nav>
                            
                            </div>                            
                        </header>

                        <main>

                            <section id="home" className="d-flex align-items-center position-relative vh-100 cover hero" style={{backgroundImage: "url('https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Gutenberg_Bible%2C_Lenox_Copy%2C_New_York_Public_Library%2C_2009._Pic_01.jpg/1200px-Gutenberg_Bible%2C_Lenox_Copy%2C_New_York_Public_Library%2C_2009._Pic_01.jpg')"}}>
                                <div className="card container-fluid container-fluid-max">
                                       
                                            <div className="card-body"  style={{backgroundColor: 'rgba(245, 245, 245, 0.4)',borderRadius: '1.2em'}} onClick= {() => {  document.location.href = "/search" }} >
                                           
                                                <h4 className="card-title">Closed User Group Online (CUG).
                                                This web site is not for general access, it is a group wikipedia that limits access beyond the defined set of group members.
                                                             <a href="https://cugonline.co.za/referencematerial"> To browse Biblopedia click here </a> 
                                                           
                                                </h4>                                              
                                            </div>                                      
                                </div>
                              
                            </section>
                            
                            <section  id="process" className="d-flex cover hero" style={{backgroundImage: "url('https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Gutenberg_Bible%2C_Lenox_Copy%2C_New_York_Public_Library%2C_2009._Pic_01.jpg/1200px-Gutenberg_Bible%2C_Lenox_Copy%2C_New_York_Public_Library%2C_2009._Pic_01.jpg')"}} >                                   
                                <div className="container-fluid container-fluid-max">
                                    <div className="row text-center py-5"  onClick= {() => {  document.location.href = "/referencematerial" }}>
                                        <div className="col-12 pb-4">
                                            <h2 className="text-white">About Biblopedia</h2>
                                        </div>
                                       
                                        <div className="col-12 col-sm-12 col-lg-12">
                                            <span className="fa-stack fa-2x">
                                            <i className="fas fa-circle fa-stack-2x text-blue"></i>
                                            <i className="fas fa-bible fa-stack-1x text-white"></i>
                                            </span>
                                            
                                            <p className="mt-3 text-white h6">I wish to contact persons outside of the Hales and Paynter groups...</p>
                                           
                                            </div>
                                         </div>
                                </div>
                            </section>
                            {/* <section id="featuredItems" className="d-flex cover hero" style={{backgroundImage: "url('https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Gutenberg_Bible%2C_Lenox_Copy%2C_New_York_Public_Library%2C_2009._Pic_01.jpg/1200px-Gutenberg_Bible%2C_Lenox_Copy%2C_New_York_Public_Library%2C_2009._Pic_01.jpg')"}} >
                                <div className="container-fluid container-fluid-max">
                                    <div className="row text-center py-5" >
                                     
                                        <div className="col-6 col-sm-6 col-lg-6">
                                            <span className="fa-stack fa-2x">
                                            <i className="fas fa-circle fa-stack-2x text-blue"></i>
                                            <i className="fas fa-hand-holding-water fa-stack-1x text-white"></i>
                                            </span>
                                            <h3 className="mt-3 text-white h4">Aqua</h3>
                                            <p className="mt-3 text-white h6">Lorem ipsum dolor sit amet consectetur adipisicing elit sed repudiandae.</p>
                                        </div>

                                        <div className="col-6 col-sm-6 col-lg-6">
                                         
                                         <span className="fa-stack fa-2x">
                                            <i className="fas fa-circle fa-stack-2x text-blue"></i>
                                            <i className="fas fa-radiation-alt fa-stack-1x text-white"></i>
                                            </span>
                                            <h3 className="mt-3 text-white h4">Radiation</h3>
                                            <p className="mt-3 text-white h6">Nor again is there anyone who loves or pursues or desires to obtain pain.</p>
                                                                                   
                                        </div> 

                                    </div>
                                </div>
                            </section> */}
                        </main>                        
                    </body>
                </Router>   
              ); 
        }
    
}

export default Home;
