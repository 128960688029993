import React, { Component } from 'react';  
import './App.css';  
import ReactTable   from 'react-table';
import { Form, Container , Row , Col} from 'reactstrap';  

  const _url = (true == true) ? 'https://geared4it.net/Api/biblo/' : 'http://localhost:54444/Api/biblo/';
    
  class Biblopedia extends Component {  
    constructor(props){
        super(props);                 
             this.state = {  
                Header : '',
                PageLeft : '',
                PageRight : '',
                Comments : '',
                PageBibloTitle : '',
                CopyRight : '',
                PageBibloBody : '',
                PageRefTitle : '',
                
                showDashAdminButtons : false,

                 StartPageVisible : true,
                 BibloVisible : false,
                 ReferencesVisible : false 
            }
            this.Header = this.Header.bind(this);
            this.PageLeft = this.PageLeft.bind(this); 
            this.PageRight = this.PageRight.bind(this); 
            this.Comments = this.Comments.bind(this); 
    
            this.PageBibloTitle = this.PageBibloTitle.bind(this); 
            this.CopyRight = this.CopyRight.bind(this); 
            this.PageBibloBody = this.PageBibloBody.bind(this); 
            this.PageRefTitle = this.PageRefTitle.bind(this);
        }

        Header(event) {this.setState({ Header: event.target.value }) }
        PageLeft(event) {this.setState({ PageLeft: event.target.value }) }
        PageRight(event) {this.setState({ PageRight: event.target.value }) }
        Comments(event) {this.setState({ Comments: event.target.value }) }  

        PageBibloTitle(event) {this.setState({ PageBibloTitle: event.target.value }) } 
        CopyRight(event) {this.setState({ CopyRight: event.target.value }) } 
        PageBibloBody(event) {this.setState({ PageBibloBody: event.target.value }) } 
        PageRefTitle(event) {this.setState({ PageRefTitle : event.target.value})}


    handleStartShow = ()=>{
        this.state.BibloVisible = false;
        this.state.ReferencesVisible = false;
        this.setState({ StartPageVisible: true })
    }
  
    handleBiblopediaShow = () =>{
        this.state.StartPageVisible = false;
        this.state.ReferencesVisible = false;

        this.setState({ BibloVisible : true})
    }

    handleReferencesShow = ()=>{
        this.state.BibloVisible = false;
        this.state.StartPageVisible = false;
        this.setState({ ReferencesVisible: true })
    }

    componentDidMount() {   
        
        let expiryDate = new Date();

        var day = expiryDate.getDate();
        var month = expiryDate.getMonth();
        var year = expiryDate.getFullYear();
        var expiryDateString = day + '-' + month + '-' + year;

        if (expiryDateString != localStorage.getItem("expiryDate") || localStorage.getItem("userId") == "") {
            
            localStorage.setItem("userName", "")
            localStorage.setItem("userCategoryRole", "")
            localStorage.setItem("userIsAdmin", "")
            localStorage.setItem("userId", "")
            localStorage.setItem("userIsSuperAdmin","")       
            localStorage.setItem("expiryDate","");     

            this.props.history.push("/biblologin"); 
        } 

        this.loadBibloInfo();  
        
        
        this.setState({
            showDashAdminButtons: (localStorage.getItem("userIsSuperAdmin") !== "true") ? false : true
        });
    }

    loadBibloInfo(){
        // eslint-disable-next-line no-restricted-globals
        fetch(_url + 'GetBiblioInfo',{
            method : "GET"
        }).then((response) => response.json())
          .then(response => {
            let items = response;
            this.setState({
               PageRight : items[4].Body,
               PageLeft : items[5].Body,               
               Comments : items[8].Body,

               PageBibloTitle : items[7].Body,
               CopyRight : items[2].Body,
               PageBibloBody : items[6].Body,
               PageRefTitle : items[1].Body
            })
        })

    }

    render() {  

        return (  
            <div  className="row mb-2 pageheading">  
            
                    {/*Figures Manager  */}
                    <div className="col-sm-12">
                        <br/>
                        <div className="col-sm-12">
                            <button type="button" className="btn btn-primary mr-3 float-left" style={{display:(this.state.showDashAdminButtons? 'inline':'none')}} onClick={() => { this.props.history.push("/Dashboard"); }}>Dashboard</button> 
                            <button type="button" className="btn btn-primary mr-3 float-left" style={{display:(this.state.showDashAdminButtons? 'inline':'none')}} onClick={() => { this.props.history.push("/bibloinfo"); }}>Manage Reference Material</button> 
                            <button type="button" className="btn btn-secondary mr-3 float-left" onClick={() => {document.location.href = "/biblologin"; }}>Logout</button>   
                            
                            <button type="button" className="btn btn-info mr-3 float-right" onClick={this.handleStartShow}>Home</button>   
                            <button type="button" className="btn btn-info mr-3 float-right" onClick={this.handleBiblopediaShow}>Biblopaedia</button>   
                            <button type="button" className="btn btn-info mr-3 float-right" onClick={this.handleReferencesShow}>References</button>  
                        </div>
                        <br/> <br/> <br/>
                    
                    </div>
                    <div className="col-sm-12">
                    <div className="form-group row">

        {this.state.StartPageVisible ? 
          <Container>
                <Row>
                    <Col><h1>An appeal to lovers of the Recovery of the Truth</h1></Col>
                </Row> 
            <hr/>
                <Row>
                    <Col xs="6" style={{border : "solid",borderWidth:"thin",borderColor:"lightgray"}}>                      
                        <p className="bodyWidth" dangerouslySetInnerHTML={{__html: this.state.PageLeft}}>
                        </p>
                    </Col>
                
                    <Col xs="6" style={{border : "solid",borderWidth:"thin",borderColor:"lightgray"}}>
                    <p className="bodyWidth" dangerouslySetInnerHTML={{__html: this.state.PageRight}}>
                    </p>
                    </Col>
                </Row>
            <br/>
            <Row>
                <Col style={{border : "solid",borderWidth:"thin",borderColor:"lightgray"}}>
                    <p className="bodyWidth" dangerouslySetInnerHTML={{__html: this.state.Comments}}> 
                    </p>
                </Col>
            </Row>
            </Container>
                     : null }

            {this.state.BibloVisible ?
                        <Container style={{textAlign:"left"}}>
                              <Row>
                                <Col><h1>{this.state.PageBibloTitle || ''}</h1></Col>
                            </Row> 
                            <Row>
                                {/* <Col xs="2"></Col> */}
                                <Col xs="12" style={{border : "solid",borderWidth:"thin",borderColor:"lightgray"}}>                      
                                    <p className="bodyWidth" dangerouslySetInnerHTML={{__html: this.state.CopyRight}}>
                                    </p>
                                </Col>
                                {/* <Col xs="2"></Col> */}
                            </Row>
                            <Row>
                            {/* <Col xs="2"></Col> */}
                                <Col xs="12" style={{border : "solid",borderWidth:"thin",borderColor:"lightgray"}}>                      
                                    <p className="bodyWidth" dangerouslySetInnerHTML={{__html: this.state.PageBibloBody}}>
                                    </p>
                                </Col>
                                {/* <Col xs="2"></Col> */}
                            </Row>
                        </Container>
                            : null }

                         {this.state.ReferencesVisible ?
                        <Container style={{textAlign:"left"}}>
                            <Row>
                                <Col><h1>Reference Material</h1></Col>
                            </Row>
                            <Row>                              
                              
                                <Col xs="12">                                   
                                        <p className="bodyWidth" dangerouslySetInnerHTML={{__html: this.state.PageRefTitle}}>
                                        </p>
                                </Col>
                                <Col xs="12">
                                <ClsFileManager/>
                                </Col>
                            </Row>

                        </Container>
                            : null }
                        </div>       
                    </div>
                
            </div> 
        );  
    }
      
}  

class ClsFileManager extends React.Component {
    constructor(props){
        super(props);
        this.state = { FileName : '', FilePath : '', 
                       FileComment :'', Files : [] , FileId : -1,
                       referenceFiles:[]

                    }
        this.FileName = this.FileName.bind(this);  
        this.FilePath = this.FilePath.bind(this);  
        this.FileComment = '';
        this.Files = this.Files.bind(this);
        this.FileId = this.FileId.bind(this);
        
    }

    FileName(event) {   this.setState({ FileName: event.target.value })  } 
    FilePath(event) {  this.setState({ FilePath: event.target.value }) }     

    Files(event) { this.setState({ Files : event.target.value})}  
    FileId(event) { this.setState({ FileId : event.target.value })}
    

    state = {
        selectedFile : null
    }        
    componentDidMount(){
        this.loadBibloImages();
    }

    //functions
    loadBibloImages(){
     
         // eslint-disable-next-line no-restricted-globals        
         fetch(_url + 'GetBibloFiles', {
             method : "GET"
         }).then((response) => response.json())//get json object
           .then(response => {            
               let files = response;
               this.setState({
                  // Id : file.Id,                      
                //    FileComment : files.FileComment ,
                //    Files : files,
                //    FileId : files.FileId
                referenceFiles : response
                             })
                             
           })
    }

    fileSelectedHandler = event => {
        this.setState({
            selectedFile : event.target.files[0]
        })            
    }

    getFileComment = (e) => {
        this.setState({              
          FileComment : e.target.value              
        });
      }

  

    getFileComment = (e) => {
        this.setState({              
          FileComment : e.target.value              
        });
      }

    
    render() {   
        
        const col_referenceFiles = [
            // {
            //     Header : () => (
            //         <div
            //           style={{
            //             textAlign:"left",
                        
            //           }}
            //         ><strong>Sort Order</strong></div>),
            //         accessor : "SortOrder" ,
            //         width: 110               
            // },
            {
            Header : () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                ><strong>Title</strong></div>),                
                accessor : "ThumbNail"
        },      
        {
            Header : () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                ><strong>Description</strong></div>),
                accessor : "FileComment"
                
        },
        {
            Header : () => (
                    <div style={{ textAlign : "left" , width : "7px"}}>
                       
                        <strong>Actions</strong>  </div>
            ),
            
                    width: 110 ,

            Cell: (props)=> (
                <div style={{textAlign:"center"}}>
                    {/* <button style={{backgroundColor: "green"}} >
                      <a  href={(props.original.FilePath)}  style={{color:"white"}}> View</a>
                    </button>
                     */}
                     <button className="btn btn-success" style={{ backgroundColor: "green" , color:"#fefefe" , display:"inline"}}
                               onClick={()=> window.location.href= props.original.FilePath}>View</button>
                    {/* &nbsp; &nbsp;
                    <button style={{backgroundColor: "green"}} >
                      <a  onClick={()=>{this.handleModal(props)}} style={{color:"white"}}> Edit</a>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button style={{backgroundColor: "red" , color:"#fefefe"}}
                         onClick={() =>{ this.removeFile(props)}}> Delete  </button> */}
                </div>
                
                 
            )}
    ]
      return (
                  
        <div className="col-sm-12" style={{border : "solid",borderWidth:"thin",borderColor:"#212529"}}>
            
             <hr/>
             <div className="col-sm-12" style={{paddingBottom: '5px'}}> 
                <ReactTable 
                                        columns={col_referenceFiles}
                                        noDataText="no records..."
                                        data= {this.state.referenceFiles}
                                        filterable
                                        defaultPageSize={40}>
                                                
                </ReactTable>
                </div>
        </div>            
      );
    }
  }


export default Biblopedia;