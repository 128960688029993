import React, { Component } from 'react';  
import './App.css';  
import { Button, Input } from 'reactstrap';  
import ReactTable   from 'react-table';
import "react-table/react-table.css";
import {  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const _url = (true == true) ? 'https://geared4it.net/Api/biblo/' : 'http://localhost:54444/Api/biblo/';

class bibloinfo extends Component { 
    constructor(props){
        super(props);

        this.state = {
            PageLeft : '',
            PageRight : '',
            Comments : '',
            PageBibloTitle : '',
            CopyRight : '',
            PageBibloBody : '',
            PageRefTitle : '',
            StartPageVisible : true,
            BibloVisible : false,
            ReferencesVisible : false
        }

        this.PageLeft = this.PageLeft.bind(this); 
        this.PageRight = this.PageRight.bind(this); 
        this.Comments = this.Comments.bind(this); 

        this.PageBibloTitle = this.PageBibloTitle.bind(this); 
        this.CopyRight = this.CopyRight.bind(this); 
        this.PageBibloBody = this.PageBibloBody.bind(this); 
        this.PageRefTitle = this.PageRefTitle.bind(this);

        this.saveBibloDetails = this.saveBibloDetails.bind(this);
       
    }

    PageLeft(event) {this.setState({ PageLeft: event.target.value }) }
    PageRight(event) {this.setState({ PageRight: event.target.value }) }
    Comments(event) {this.setState({ Comments: event.target.value }) } 

    PageBibloTitle(event) {this.setState({ PageBibloTitle: event.target.value }) } 
    CopyRight(event) {this.setState({ CopyRight: event.target.value }) } 
    PageBibloBody(event) {this.setState({ PageBibloBody: event.target.value }) } 
    PageRefTitle(event) {this.setState({PageRefTitle : event.target.value})}
    
    componentDidMount(){
        this.loadBibloDetails();
    } 

    loadBibloDetails(){
        // eslint-disable-next-line no-restricted-globals          
        fetch(_url + 'GetBiblioInfo',{
            method : "GET"
        }).then((response) => response.json())
         .then(response => {
             let items = response;
             this.setState({
                PageRight : items[4].Body,
                PageLeft : items[5].Body,               
                Comments : items[8].Body,

                PageBibloTitle : items[7].Body,
                CopyRight : items[2].Body,
                PageBibloBody : items[6].Body,
                PageRefTitle : items[1].Body
             })
         })
    }

    saveBibloDetails(event){
        event.preventDefault();
        
        fetch(_url + 'EditBibloInfo',{
                method: "POST",
                headers:{
                    'Accept': 'application/json',  
                    'Content-Type': 'application/json' 
                },
                body : JSON.stringify({
                    PageRight : this.state.PageRight,
                    PageLeft : this.state.PageLeft,               
                    Comments : this.state.Comments,
                    PageBibloTitle : this.state.PageBibloTitle,
                    CopyRight : this.state.CopyRight,
                    PageBibloBody : this.state.PageBibloBody,
                    PageRefTitle : this.state.PageRefTitle
                })
        }).then((response) => response.json())
          .then((result) => {
              if(result.Status !== 'Error'){
                var newPageUrl = document.location.origin + "/ReferenceMaterial";    
                window.open(newPageUrl, "_blank") //to open new page
              }
              else 
                {
                    alert(' Error saving Details!')  
                } 
          })
    }

   

    handleStartShow = ()=>{
        this.state.BibloVisible = false;
        this.state.ReferencesVisible = false;
        this.setState({ StartPageVisible: !this.state.StartPageVisible })
    }
  
    handleBiblopediaShow = () =>{
        this.state.StartPageVisible = false;
        this.state.ReferencesVisible = false;
        this.setState({ BibloVisible : !this.state.BibloVisible })
    }

    handleReferencesShow =()=>{        
        this.state.StartPageVisible = false;
        this.state.BibloVisible = false;

        this.setState({ ReferencesVisible : !this.state.ReferencesVisible})
    }

    render(){
        return(
           
            <div className="col-sm-12">                       
             <br/>
            
                     <div className="row">                         
                            <div className="col-sm-6">
                                <button type="button" className="btn btn-primary mr-2 float-left" onClick={() => { this.props.history.push("/Dashboard"); }}>Dashboard</button> 
                                <button type="button" className="btn btn-info mr-2 float-left"  onClick={this.handleStartShow}>Manage Start Page</button>                                        
                                <button type="button" className="btn btn-info mr-2 float-left" onClick={ this.handleBiblopediaShow }> Biblopedia</button> 
                                <button type="button" className="btn btn-info mr-2 float-left" onClick={ this.handleReferencesShow }> References</button> 
                                
                                <button type="button" className="btn btn-info mr-2 float-left" onClick={() => { this.props.history.push("/ReferenceMaterial"); }}>View Live</button>                                                                         
                            </div>                           
                     </div>
                     
                     {this.state.StartPageVisible ? 
                     <div>
                        <div className="row">
                            <div className="col-sm-12">  
                                <Button  className="btn btn-primary mr-3 float-right" onClick= { this.saveBibloDetails } color="success">Save and Preview</Button>   
                            </div>
                        </div>
                     
                     <div className="col-sm-12">    
                                     
                            <strong>Left Pane</strong>
                                <Input type="textarea" value={this.state.PageLeft || ''} onChange={this.PageLeft.bind(this)} placeholder="PageLeft" rows="15" />                        
                     </div>

                     <div className="col-sm-12"><br/>
                     <strong>Right Pane</strong>
                            <Input type="textarea" value={this.state.PageRight || ''} onChange={this.PageRight.bind(this)} placeholder="PageRight" rows="15"/>                                                  
                     </div>

                     <div className="col-sm-12"><br/>                   
                         <strong>Comments Pane</strong> 
                        <Input type="textarea" value={this.state.Comments || ''} onChange={this.Comments.bind(this)} placeholder="Comments" rows="15"/>                         
                     </div>

                     <div className="row">
                        <div className="col-sm-12"><br/>  
                            <Button  className="btn btn-primary mr-3 float-right" onClick= { this.saveBibloDetails } color="success">Save and Preview</Button>   
                        </div>
                     </div>               
                     </div>
                     : null }
                     
                     {this.state.BibloVisible ?
                     <div>
                        <div className="row">
                            <div className="col-sm-12">  
                                <Button  className="btn btn-primary mr-3 float-right" onClick= { this.saveBibloDetails } color="success">Save and Preview</Button>   
                            </div>
                        </div>
                     
                     <div className="col-sm-12"> <br/>                     
                            <strong>Biblo Title</strong>
                                <Input type="text" value={this.state.PageBibloTitle || ''} onChange={this.PageBibloTitle.bind(this)} placeholder="Biblo Title"  />                        
                     </div>

                     <div className="col-sm-12">  <br/>                       
                            <strong>Biblo Content</strong>
                                <Input type="textarea" value={this.state.PageBibloBody || ''} onChange={this.PageBibloBody.bind(this)} placeholder="Biblo Body" rows="15" />                        
                     </div>

                     <div className="col-sm-12"><br/>
                     <strong>Copy Right</strong>
                            <Input type="textarea" value={this.state.CopyRight || ''} onChange={this.CopyRight.bind(this)} placeholder="Copy Right" rows="15"/>                                                  
                     </div>
                     <br/>
                     <div className="row"><br/>
                            <div className="col-sm-12">  
                                <Button  className="btn btn-primary mr-3 float-right"  onClick= { this.saveBibloDetails } color="success">Save and Preview</Button>   
                            </div>
                        </div>
                     </div>
                     : null }

                     {this.state.ReferencesVisible ?
                    <div>
                   
                        <div className="row"><br/>
                            <div className="col-sm-12"> <br/>                     
                                <strong>Reference Content</strong>
                                    <Input type="textarea" value={this.state.PageRefTitle || ''} onChange={this.PageRefTitle.bind(this)} placeholder="Reference Body" rows="15" />                        
                            </div>
                        </div>
                        <div className="row"> 
                            <div className="col-sm-12">  <br/>
                                <Button  className="btn btn-primary mr-3 float-right"   onClick= { this.saveBibloDetails } color="success">Save and Preview</Button>   
                            </div>
                        </div>
                        <div className="row"> 
                           <ClsFileManager/>
                        </div>
                        
                    </div>
                    : null 
                    }
                    
            </div>
              
        )
    }

}

class ClsFileManager extends React.Component {
    constructor(props){
        super(props);
        this.state = { FileName : '', FilePath : '', 
                       FileComment :'', 
                       FileTitle : '',
                       SortOrder : '',
                       Files : [] , 
                       FileId : -1,
                       FileCommentError: '',
                       FileTitleError: '',
                       FileCommentSuccess:'',
                       referenceFiles : [],
                       show : false
                    }
                    
        this.FileName = this.FileName.bind(this);  
        this.FilePath = this.FilePath.bind(this);  
        this.FileComment = this.FileComment.bind(this);
        this.SortOrder = this.SortOrder.bind(this);
        this.FileTitle = this.FileTitle.bind(this);
        this.Files = this.Files.bind(this);
        this.FileId = this.FileId.bind(this);
        this.RemoveBibloImage = this.RemoveBibloImage.bind(this);
        this.editFileDetails = this.editFileDetails.bind(this);
        
       
    }

    FileComment(event){   this.setState({ FileComment: event.target.value })  } 
    FileTitle(event){   this.setState({ FileTitle: event.target.value })  } 
    SortOrder(event){ this.setState({ SortOrder : event.target.value})}
    FileName(event) {   this.setState({ FileName: event.target.value })  } 
    FilePath(event) {  this.setState({ FilePath: event.target.value }) }     

    Files(event) { this.setState({ Files : event.target.value})}  
    FileId(event) { this.setState({ FileId : event.target.value })}
    

    state = {
        selectedFile : null
    }        
    componentDidMount(){
        this.loadBibloImages();
    }

    //functions
    loadBibloImages(){
     
         // eslint-disable-next-line no-restricted-globals        
         fetch(_url + 'GetBibloFiles', {
             method : "GET"
         }).then((response) => response.json())//get json object
           .then(response => {            
          

                this.setState({
                    referenceFiles : response
                })
           })
    }

    ShowResult = (e) =>{
        this.setState({              
            FileCommentSuccess : 'Upload successful!'            
          });
    }

    fileSelectedHandler = event => {
        this.setState({
            selectedFile : event.target.files[0]
        })            
    }


      validate =() => {
        let FileCommentError = "";
        let FileTitleError = "";
   
        if(this.state.selectedFile == undefined){
            FileCommentError = "Please select a file to upload";
        }

        if(!this.state.FileComment) FileCommentError = "File description cannot be blank";
        if(!this.state.FileTitle) FileTitleError = "File title cannot be blank";
     
        if(FileCommentError){
            this.setState({FileCommentError});
            return false;
        }else{
            this.setState({FileCommentError : ""});
        }

        if(FileTitleError){
            this.setState({FileTitleError});
            return false;
        }else{
            this.setState({FileTitleError : ""});
        }

        return true;
    }
    fileUploadHandler = () => {        
       
        const isValid = this.validate();

        if(isValid){
            const fd = new FormData();
            const description = this.state.FileComment;
            const title = this.state.FileTitle;
            
            
            fd.append('file',this.state.selectedFile,this.state.selectedFile.name); 
    
                fetch(_url + 'UploadBiblo?description='+description+'&title='+ title,{
                    method: 'POST',                    
                    body : fd                   
                }
                )
                .then(res => {                   
                    //this.ShowResult();
                    this.loadBibloImages();
                });

        }     
    }

    getFileComment = (e) => {
        this.setState({              
          FileComment : e.target.value              
        });
      }

      getSortOrder = (e) => {
          this.setState({
              SortOrder : e.target.value
          });
      }
      
      getFileTitle = (e) => {
        this.setState({              
          FileTitle : e.target.value              
        });
      }
    RemoveBibloImage(event){       
        event.preventDefault();
    }
   
    editFileDetails(event){
        event.preventDefault();
        const description = this.state.FileComment;
        const title = this.state.FileTitle;
        const id = this.state.FileId;
        const sortOrder = this.state.SortOrder;

        fetch(_url + 'EditFileDetails?description='+description+'&title='+ title+'&id='+ id+'&sortOrder='+ sortOrder,{
            method: 'POST'             
        }
        )
        .then(res => {                   
           this.setState({show: !this.state.show})
            this.loadBibloImages();
        });
    }

    handleModal(props){    
        
            this.setState({ FileTitle  : (props != null) ? props.original.FileTitle : '' })
            this.setState({ FileComment  : (props != null) ? props.original.FileComment : '' })
            this.setState({ SortOrder  : (props != null) ? props.original.SortOrder : '' })
            this.setState({ FileId  : (props != null) ? props.original.FileId : '' })
              
        this.setState({ show : !this.state.show})
    }

    removeFile(props){     
        
        const id = props.original.FileId;
        fetch(_url + 'DeleteFile?id='+ id,{          
            method: 'POST'
            
        }).then((Response) => Response.json())
        .then((Result) => {
            if (Result.Status === 'Success') {
                this.loadBibloImages();
            } else  {
                alert(' Error deleting Reference!')  
            } 
            
        })
    }

    render() {    
        
        const col_referenceFiles = [
            {
                Header : () => (
                    <div
                      style={{
                        textAlign:"left",
                        
                      }}
                    ><strong>Sort Order</strong></div>),
                    accessor : "SortOrder" ,
                    width: 110               
            },
            {
            Header : () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                ><strong>Title</strong></div>),               
                accessor : "ThumbNail"
        },      
        {
            Header : () => (
                <div
                  style={{
                    textAlign:"left"
                  }}
                ><strong>Description</strong></div>),
                accessor : "FileComment"
                
        },

        {

            Header : "Actions",
            width: 210 ,
            Cell : props => {
                return(
                    <div>
                    {/* <button style={{backgroundColor: "green"}} >
                      <a  href={(props.original.FilePath)}  style={{color:"white"}}> View</a>
                    </button> */}
                     <button className="btn btn-success" style={{ backgroundColor: "green" , color:"#fefefe" , display:"inline"}}
                               onClick={()=> window.location.href= props.original.FilePath}>View</button>
                            
                    &nbsp; &nbsp;
                    {/* <button style={{backgroundColor: "green"}} >
                      <a  onClick={()=>{this.handleModal(props)}} style={{color:"white"}}> Edit</a>
                    </button> */}
                     <button className="btn btn-warning" style={{ backgroundColor: "orange" , color:"#fefefe" , display:"inline"}}
                               onClick={()=>{this.handleModal(props)}}>Edit</button>
                    
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-danger" style={{ backgroundColor: "red" , color:"#fefefe" , display:"inline"}}
                               onClick={()=>{this.removeFile(props)}}>Delete</button>
                    {/* <button style={{backgroundColor: "red" , color:"#fefefe"}}
                         onClick={() =>{ this.removeFile(props)}}> Delete  </button> */}
                </div>
                )
            }
        }//,
        // {
        //     Header : () => (
        //             <div style={{ textAlign : "left" , width : "7px"}}>
                       
        //                 <strong>Actions</strong>  </div>
        //     ), 
        //      width: 210 , 
        //     Cell: (props)=> (
        //         <div>
        //             <button style={{backgroundColor: "green"}} >
        //               <a  href={(props.original.FilePath)}  style={{color:"white"}}> View</a>
        //             </button>
        //              <button className="btn btn-success" style={{marginRight:"7px" ,backgroundColor: "green" , color:"#fefefe" , display:(this.state.showButton? 'inline':'none')}}
        //                        onClick={()=> props.original.FilePath}>View</button>
                            
        //             &nbsp; &nbsp;
        //             <button style={{backgroundColor: "green"}} >
        //               <a  onClick={()=>{this.handleModal(props)}} style={{color:"white"}}> Edit</a>
        //             </button>
        //             &nbsp;&nbsp;&nbsp;
        //             <button style={{backgroundColor: "red" , color:"#fefefe"}}
        //                  onClick={() =>{ this.removeFile(props)}}> Delete  </button>
        //         </div>
                
                 
        //     )}
    ]
      return (
              
        <>
        <div className="col-sm-12">
             <hr/>
           
                    <div className="row">
                        <div className="col-sm-6"  style={{paddingBottom: '5px'}}>
                        <input type="text" value={this.state.FileTitle || ''}    onChange={this.getFileTitle} placeholder="File Title" className="form-control"/>  
                        <div style={{fontSize:'10', color: 'red'}}>{this.state.FileTitleError}</div>
                        </div>
                    </div>
                 <div className="row">
                    <div className="col-sm-6"  style={{paddingBottom: '5px'}}>
                        <input type="text" value={this.state.FileComment || ''}    onChange={this.getFileComment} placeholder="File Description" className="form-control"/>  
                        <div style={{fontSize:'10', color: 'red'}}>{this.state.FileCommentError}</div>
                        <div style={{fontSize:'10', color: 'green'}}>{this.state.FileCommentSuccess}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6"  style={{paddingBottom: '5px'}}>
                        <input type="file" id="file" name="file" onChange={ this.fileSelectedHandler} />                        
                        <button className="btn btn-success pull-right" onClick={this.fileUploadHandler}>Upload</button>
                    </div>
                   
                </div>
          
                <hr/>               
               
                <div className="col-sm-12" style={{paddingBottom: '5px'}}> 
                <ReactTable 
                                        columns={col_referenceFiles}
                                        noDataText="no records..."
                                        data= {this.state.referenceFiles}
                                        filterable
                                        defaultPageSize={40}>
                                                
                </ReactTable>
                </div>
        </div>  
                  {/* edit modal  */}     
                     <Modal isOpen={this.state.show}>
                         <ModalHeader>
                             Edit Reference File
                         </ModalHeader>
                         <ModalBody>
                         <div className="row">
                         <div className="col-sm-12"  style={{paddingBottom: '5px'}}>
                                <input type="text" value={this.state.SortOrder || ''}    onChange={this.getSortOrder} placeholder="Sort Order" className="form-control"/>  
                                
                            </div>
                            <div className="col-sm-12"  style={{paddingBottom: '5px'}}>
                                <input type="text" value={this.state.FileTitle || ''}    onChange={this.getFileTitle} placeholder="File Title" className="form-control"/>  
                                <div style={{fontSize:'10', color: 'red'}}>{this.state.FileTitleError}</div>
                            </div>
                            <div className="col-sm-12"  style={{paddingBottom: '5px'}}>
                                <input type="text" value={this.state.FileComment || ''}    onChange={this.getFileComment} placeholder="File Description" className="form-control"/>  
                                <div style={{fontSize:'10', color: 'red'}}>{this.state.FileCommentError}</div>
                                <div style={{fontSize:'10', color: 'green'}}>{this.state.FileCommentSuccess}</div>
                         </div>
                         <div className="col-sm-12"  style={{paddingBottom: '5px',textAlign: 'end'}}>
                           
                            <button className="btn btn-success pull-right" onClick={this.editFileDetails}>Save</button>
                        </div>
                    </div>                
              
                         </ModalBody>
                         <ModalFooter>
                             <Button onClick={()=>{this.handleModal(null)}}>
                             Close 
                             </Button>
                             
                         </ModalFooter>
                     </Modal>

        </>          
      );
    }
  }




export default bibloinfo;