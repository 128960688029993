import React, { Component } from 'react';  
// import './App.css';  
import ReactTable   from 'react-table';
import "react-table/react-table.css";
import { Button,  Form } from 'reactstrap'; 
import Select from 'react-select'; 

const _url = (true == true) ? 'https://geared4it.net/Api/Contacts/' : 'http://localhost:54444/Api/Contacts/';

const initialState = {
    contacts : [],
    Id : -1,
    CompanyName : '',
    CompanyAddress : '',
    Industry : '',
    ContactNumbers : '',            
    OtherNumbers : '', 
    CellNumbers : '',               
    EmailAddress : '',
    WebAddress : '',
    IsProcessed: false , 
    DateImported : '',
    DateProcessed : ''
    //refactor for normalization

    //do error validation
}

class Contacts extends Component {
    constructor(props){
        super(props);

        this.state = initialState;//initialise contact state
        
        this.CompanyName = this.CompanyName.bind(this);
        this.CompanyAddress = this.CompanyAddress.bind(this);
        this.Industry = this.Industry.bind(this);
        this.ContactNumbers = this.ContactNumbers.bind(this);
        this.OtherNumbers = this.OtherNumbers.bind(this);
        this.CellNumbers = this.CellNumbers.bind(this);
        this.EmailAddress = this.EmailAddress.bind(this);
        this.WebAddress = this.WebAddress.bind(this);
        this.DateImported = this.DateImported.bind(this);
    }
    //bind events
    CompanyName(event) { this.setState({ CompanyName: event.target.value})}
    CompanyAddress(event) { this.setState({ CompanyAddress: event.target.value})}
    Industry(event) { this.setState({ Industry: event.target.value})}
    ContactNumbers(event) { this.setState({ ContactNumbers: event.target.value})}
    OtherNumbers(event) { this.setState({ OtherNumbers: event.target.value})}
    CellNumbers(event) {this.setState({CellNumbers:event.target.value})}
    EmailAddress(event) { this.setState({ EmailAddress: event.target.value})}
    WebAddress(event) { this.setState({ WebAddress: event.target.value})}
    DateImported(event) { this.setState({ DateImported: event.target.value})}

    //
    componentDidMount()
    {       
        this.loadContacts();
    }

    loadContacts =()=> {
        // eslint-disable-next-line no-restricted-globals
        fetch(_url + `SystemContacts`,{
            method:"GET"
        }).then(resp=> resp.json())//get json from server
          .then(contacts=>{//set contacts object
              this.setState({contacts:contacts})
          })
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }
    
        return true;
    };

   render(){

    //build table columns
    const columns = [
        {
            Header : () => (               
                <div style={{ textAlign : "left"}}>Company </div>
            ),
            Cell : row => (               
                    <div style={{ textAlign : "left"}}>
                        {row.original.CompanyName}
                    </div>
            ),
            accessor : "CompanyName",
            sortable: true,
            filterable : true
            },
            {
                Header : () => (               
                    <div style={{ textAlign : "left"}}>Address </div>
                ),
                Cell : row => (               
                        <div style={{ textAlign : "left"}}>
                            {row.original.CompanyAddress}
                        </div>
                ),
                accessor : "CompanyAddress",
                sortable: true,
                filterable : true
                },
                {
                    Header : () => (               
                        <div style={{ textAlign : "left"}}>Industry</div>
                    ),
                    Cell : row => (               
                            <div style={{ textAlign : "left"}}>
                                {row.original.Industry}
                            </div>
                    ),
                    accessor : "Industry",
                    sortable: true,
                    filterable : true
                    },
                    {
                        Header : () => (               
                            <div style={{ textAlign : "left"}}>Contact Numbers </div>
                        ),
                        Cell : row => (               
                                <div style={{ textAlign : "left"}}>
                                    {row.original.ContactNumbers}
                                </div>
                        ),
                        accessor : "ContactNumbers",
                        sortable: true,
                        filterable : true
                        },
                        {
                            Header : () => (               
                                <div style={{ textAlign : "left"}}>OtherNumbers </div>
                            ),
                            Cell : row => (               
                                    <div style={{ textAlign : "left"}}>
                                        {row.original.OtherNumbers}
                                    </div>
                            ),
                            accessor : "OtherNumbers",
                            sortable: true,
                            filterable : true
                            },
                            {
                                Header : () => (               
                                    <div style={{ textAlign : "left"}}>Cell Numbers </div>
                                ),
                                Cell : row => (               
                                        <div style={{ textAlign : "left"}}>
                                            {row.original.CellNumbers}
                                        </div>
                                ),
                                accessor : "CellNumbers",
                                sortable: true,
                                filterable : true
                                },
                                {
                                    Header : () => (               
                                        <div style={{ textAlign : "left"}}>Email Address </div>
                                    ),
                                    Cell : row => (               
                                            <div style={{ textAlign : "left"}}>
                                                {row.original.EmailAddress}
                                            </div>
                                    ),
                                    accessor : "EmailAddress",
                                    sortable: true,
                                    filterable : true
                                    },
                                    {
                                        Header : () => (               
                                            <div style={{ textAlign : "left"}}>Web Address </div>
                                        ),
                                        Cell : row => (               
                                                <div style={{ textAlign : "left"}}>
                                                    {row.original.WebAddress}
                                                </div>
                                        ),
                                        accessor : "WebAddress",
                                        sortable: true,
                                        filterable : true
                                        }
    ]
       return(<div>
           <div className="row">  
            <div className="col-sm-12">
            <ReactTable columns={columns}
                     defaultFilterMethod={this.filterCaseInsensitive}
                    noDataText="loading"
                        data= {this.state.contacts}
                        filterable
                        defaultPageSize={25} >                                
                    </ReactTable>
             </div>
           </div>
       </div>)
   }
    
    
}

export default Contacts;  
