import React , {Component}from 'react';  
import './App.css';  
import Login from './Login';  
import Register from './Register';  
import Dashboard from './Dashboard';  
import Users from './Users';
import Figures from './Figures';
import FiguresPagination from './FiguresPagination';
import FigureDetails from './FigureDetails';
import BibleReferences from './BibleReferences';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';   
//import Header from "./footerHeader/header";
import Footer from "./footerHeader/footer";
import Search from "./Search";
import Details from "./Details";
import Biblopedia from "./ReferenceMaterial";
import bibloinfo from "./bibloinfo";
import bibloLogin from "./bibloLogin";
import home from "./home"; 
import FootNoteContentManager from "./FootNoteContent";

//import importExcel from "./components/contacts/importExcel";
import contactList from "./components/contacts/contactList"
import ContactEmails from './components/contacts/contactEmails';
import GoodTeachingBible from './GoodTeachingBible';
import GoodTeachingSearchV2 from './GoodTeachingSearchV2';

  class App extends Component {

    render(){

      return (          
        <Router>    
          <div className="App loginpadding">    
          {/* <Header /> */}
          
            <Switch>    
              <Route path='/Login' component={Login} />    
              <Route path='/Register' component={Register} />  
              <Route path='/Signup' component={Register} />    
              <Route path='/Users' component={Users} />    
              <Route path='/Figures' component={Figures} /> 
              <Route path='/FiguresPagination' component={FiguresPagination}/>   
              <Route path='/BibleReferences' component={BibleReferences} />
              <Route path='/FigureDetails/:id' component={FigureDetails} />    
              <Route path='/Search' component={Search}/>
              <Route path='/Details' component={Details}/>
              <Route path='/ReferenceMaterial' component={Biblopedia}></Route>
              <Route path='/bibloinfo' component={bibloinfo}></Route>
              <Route path='/bibloLogin' component={bibloLogin}/>
              <Route path='/Dashboard' component={Dashboard} />   
              <Route path='/FootNoteContent' component={FootNoteContentManager} /> 
              {/* <Route path='/importExcel' component={importExcel} />  */}
                <Route path='/contactList' component={contactList} /> 
                <Route path='/ContactEmails' component={ContactEmails}/>
                <Route path='/GoodTeachingBible' component={GoodTeachingBible} />
                <Route path='/GoodTeachingSearchV2' component={GoodTeachingSearchV2} />
              <Route path='/' component={home} /> 
               
            </Switch>    
                    
            <Footer />
          </div>    
        </Router>   
      ); 
    }
  
}  
  
export default App;  