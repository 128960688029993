import React, { Component } from 'react';  
import { Button,  Form, Input, InputGroup } from 'reactstrap';  
import ReactTable   from 'react-table';
import Select from 'react-select';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const _url = (true == true) ? 'https://geared4it.net/Api/Contacts/' : 'http://localhost:54444/Api/Contacts/';

class ContactEmails extends Component{
    constructor(props){
//Step 1 : declare states
    }

//Step 2 : bind our events

    componentDidMount(){
//Step 3 : load/mount our  mater tools 
        //[contacts / Industry / search groups / check boxes ...dropdowns]
    }

/*Step 4: functions and events go here*/

    //then start rendering
    render(){
//Step 5 : build UI components [Textboxes / dropdowns / Text  areas]
const columns = []
//**Step 6 :We build and return UI elements
        return(<div>
            <div className="row">  
             <div className="col-sm-12">
             <ReactTable columns={columns}
                      defaultFilterMethod={this.filterCaseInsensitive}
                     noDataText="loading"
                         data= {this.state.contacts}
                         filterable
                         defaultPageSize={25} >                                
                     </ReactTable>
              </div>
            </div>
        </div>)
    }
}

export default ContactEmails;

